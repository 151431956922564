import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ChatbotService } from '../services/chat.service';
import { LocalStorageService } from '../services/localStorageService';
import { ToastrService } from 'ngx-toastr';
import { StateService } from '../services/shared-object.service';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TagPopupModalComponent } from "./tagPopupModal";
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { TranslationService } from '../services/translation-service';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FrenchCharacterValidatorService } from '../services/french-character-validator.service';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';

interface QAResponse {
  question: string;
  response: string;
}
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  queries: {
    'contentRef': new ViewChild('contentRef')
  }
})
export class AppChatbotComponent implements OnInit, OnDestroy {

  userInput: string = '';
  isAutoRatingIconEnabled: boolean = true;
  isUserRatingIconEnabled: boolean = true;
  isToCache: boolean = false;
  cacheHyperlinkClicked: boolean = false;
  stepWiseHyperlinkClicked: boolean = false;
  showModalRating: boolean = false;
  qaResponses: QAResponse[] = [];
  userTypedInformation = '';
  modalPopupResponse: any;
  showSuggestions: boolean = false;
  suggestions: string[] = [];
  isLoader: boolean = false;
  botCurrentResponse: any;
  contentRef!: ElementRef;
  contentHeight: any;
  flag: boolean | undefined;
  stepWiseQuestion: string = '';
  private clearSubscription!: Subscription;
  userProfile: any = '';
  translate: any;
  botLoderType = '';
  isDesktop = false;
  selectedOption: any;
  private autoCompleteSubscription!: Subscription;
  imageKeys: string[] = [];
  imageUrls: string[] = [];
  currentIndex: number = 0;
  previewUrl: string | null = null;
  botType: string = ''
  validationError: string | null = '';
  private loaderSubscription: Subscription | undefined;
  isResponseReceived: boolean = false;
  showTooltip: boolean = false;
  currentTooltipText: string = '';
  isExpandCollapse: boolean = true;
  IeIIsdropdownOptions: any[] = [
    { name: 'IE', value: 'IE' },
    { name: 'IIS', value: 'IIS' }
  ];
  IeIIsDropdownVisible: boolean = false;
  disabledIcon: string | null = null;
  roles: any[];
  constructor(
    public chatbotService: ChatbotService,
    private sessionStorage: LocalStorageService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private stateService: StateService,
    private sanitizer: DomSanitizer,
    private translateService: TranslationService,
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private translated: TranslateService,
    private frenchCharacterValidator: FrenchCharacterValidatorService,
    private guidedTourService: GuidedTourService
  ) {
    this.roles = sessionStorage.get('roles');
    this.selectedOption = 'chat';
    const initialChatpaload = {
      "language_type": this.sessionStorage.get('language'),
      "query": "Hi",
      "bot_type": this.sessionStorage.get('bot_type'),
      "userId": this.sessionStorage.get('username')
    }
    if (this.chatbotService?.botRespponse) {
      this.chatbotService.getActiveConversationFromDB(this.chatbotService?.botRespponse?.conversationID).subscribe((res: any) => {
        if (res?.status) {

        } else {
          this.clearChatHistory();
          this.callInitialChatAPI(initialChatpaload);
        }
      }, (error: any) => {
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      })
    } else {
      this.clearChatHistory();
      this.callInitialChatAPI(initialChatpaload);
    }

    //Do not remove this code//
    // Subscribe to router when navigation ends ends to clear chat history
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     // Call clearChatHistory() when navigation ends
    //     this.clearChatHistory();
    //   }
    // });

    this.translate = translateService;
    this.botType = this.sessionStorage.get('bot_type');
    this.stateService.changeSidenavMode('side');
    this.chatbotService.iEIisTypeValues = this.IeIIsdropdownOptions[0].name;
    if(this.chatbotService.feedbackIconDisabled){
      this.disabledIcon = this.chatbotService.givenRating;
    }
  }

  public dashboardTour: GuidedTour = {
    tourId: 'Chatbot Guide Tour',
    useOrb: false,
    steps: [
      {
        title: this.translateLabel('TOUR_WELCOME').translation,
        selector: '.guide-tour',
        content: this.translateLabel('TOUR_WELCOME_CONTENT').translation,
        orientation: Orientation.Right,
        action: () => { },
        roles: ['user']
      },
      {
        title: this.translateLabel('TOUR_NEW_CONVERSATION').translation,
        selector: '.new-convo-tour',
        content: this.translateLabel('TOUR_NEW_CONVERSATION_CONTENT').translation,
        orientation: Orientation.Right,
        action: () => { },
        roles: ['user']
      },
      {
        title: this.translateLabel('TOUR_CHAT_BOT_TITLE').translation,
        selector: '.chatbot-menu-tour',
        content: this.translateLabel('TOUR_CHAT_BOT_DESC').translation,
        orientation: Orientation.Right,
        action: () => { },
        roles: ['user']
      },
      {
        title: this.translateLabel('TOUR_CHAT_TRANSCRIPT_TITLE').translation,
        selector: '.chat-transript-menu-tour',
        content: this.translateLabel('TOUR_CHAT_TRANSCRIPT_DESC').translation,
        orientation: Orientation.Right,
        action: () => { },
        roles: ['chatanalyst', 'user']
      },
      {
        title: this.translateLabel('TOUR_CONFIG_TITLE').translation,
        selector: '.config-menu-tour',
        content: this.translateLabel('TOUR_CONFIG__DESC').translation,
        orientation: Orientation.Right,
        action: () => { },
        roles: ['configadmin']
      },
      {
        title: this.translateLabel('TOUR_VALIDATOR_TITLE').translation,
        selector: '.validator-menu-tour',
        content: this.translateLabel('TOUR_VALIDATOR__DESC').translation,
        orientation: Orientation.Right,
        action: () => { },
        roles: ['evaluator']
      },
      {
        title: this.translateLabel('TOUR_FEEDBACK_MGMT_TITLE').translation,
        selector: '.feedback-mgmt-menu-tour',
        content: this.translateLabel('TOUR_FEEDBACK_MGMT__DESC').translation,
        orientation: Orientation.TopLeft,
        action: () => { },
        roles: ['contentmanager']
      },
      {
        title: this.translateLabel('TOUR_BOT_CONFIG_TITLE').translation,
        selector: '.bot-config-menu-tour',
        content: this.translateLabel('TOUR_BOT_CONFIG__DESC').translation,
        orientation: Orientation.TopLeft,
        action: () => { },
        roles: ['botadmin']
      },
      {
        title: this.translateLabel('USER_MGMT_TITLE').translation,
        selector: '.reports-menu-tour',
        content: this.translateLabel('USER_MGMT_DESE').translation,
        orientation: Orientation.TopLeft,
        action: () => { },
        roles: ['useradmin']
      },
      {
        title: this.translateLabel('TOUR_LANGUAGE_INPUT').translation,
        selector: '.languageTargetElement',
        content: this.translateLabel('TOUR_LANGUAGE_INPUT_CONTENT').translation,
        orientation: Orientation.TopLeft,
        action: () => { },
        roles: ['user']
      },
      {
        title: this.translateLabel('IEIIS_TITLE').translation,
        selector: '.ieiisTargetElement',
        content: this.translateLabel('IEIIS_Description').translation,
        orientation: Orientation.TopLeft,
        action: () => { },
        roles: ['user']
      },
      {
        title: this.translateLabel('TOUR_ASK_QUESTION').translation,
        selector: '.input-wrapper',
        content: this.translateLabel('TOUR_ASK_QUESTION_CONTENT').translation,
        orientation: Orientation.Top,
        action: () => {
          const inputElement = document.querySelector('.input-wrapper input') as HTMLInputElement;
          if (inputElement) {
            inputElement.focus(); // Focus on input box
            inputElement.value = 'What is CIBC?'; // Type the sample question
            const event = new Event('input', { bubbles: true }); // Dispatch an input event for Angular binding
            inputElement.dispatchEvent(event);
          }
        },
        roles: ['user']
      },
      {
        title: this.translateLabel('TOUR_SEND_OPTION').translation,
        selector: '.sendTarget',
        content: this.translateLabel('TOUR_SEND_OPTION_CONTENT').translation,
        orientation: Orientation.TopLeft,
        action: () => {
          // const sendButton = document.querySelector('.sendTarget') as HTMLElement;
          // if (sendButton) {
          //   sendButton.click(); // Programmatically trigger the click
          // }
        },
        roles: ['user']
      },
      // {
      //   title: this.translateLabel('TOUR_CHAT_LOADER_TITLE').translation,
      //   selector: '.loaderTraget', // Replace with the actual loader selector
      //   content: this.translateLabel('TOUR_CHAT_LOADER_DESC').translation,
      //   orientation: Orientation.Top,
      //   action: () => { },
      //   roles: ['user']
      // },
      // {
      //   title: this.translateLabel('TOUR_CHAT_RESPONSE_TITLE').translation,
      //   selector: '.responseTarget', // Replace with the actual response section selector
      //   content: this.translateLabel('TOUR_CHAT_RESPONSE_DESC').translation,
      //   orientation: Orientation.Bottom,
      //   action: () => {
      //     console.log('Response received, highlighting rating icons');
      //   },
      //   roles: ['user']
      // }
    ] as any
  };

  startTour() {
    // Filter steps based on roles
    const filteredSteps = this.dashboardTour.steps.filter((step: any) =>
      !step.roles || step.roles.some((role: string) => this.roles.includes(role))
    );

    // Calculate total steps
    const totalSteps = filteredSteps.length;

    // Update `nextButtonText` dynamically
    const updatedSteps = filteredSteps.map((step, index) => ({
      ...step,
      nextButtonText: index < totalSteps - 1 ? `Next (${index + 1}/${totalSteps})` : 'Finish',
    }));

    // Start the tour with filtered steps
    this.guidedTourService.startTour({
      ...this.dashboardTour,
      steps: updatedSteps,
    });

    // this.guidedTourService.startTour(this.dashboardTour);
  }

  handleChangeAlignment(option: string): void {
    this.selectedOption = option;
  }
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    this.validationError = this.frenchCharacterValidator.validateFrenchCharacters(inputValue);
  }

  ngAfterViewChecked() {
    var test = this.contentHeight != this.contentRef?.nativeElement?.scrollHeight

    if (this.contentHeight != this.contentRef?.nativeElement?.scrollHeight && this.contentRef?.nativeElement?.scrollHeight != (this.contentRef?.nativeElement?.scrollTop + this.contentRef?.nativeElement?.offsetHeight)) {
      this.contentRef?.nativeElement?.scrollTo(0, this.contentRef?.nativeElement?.scrollHeight);
    }

    this.stateService.getData().subscribe((res: any) => {
      this.flag = res;
    })
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef: MatDialogRef<TagPopupModalComponent> = this.dialog.open(TagPopupModalComponent, {
      width: '100%',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        userInput: this.userTypedInformation,
        responseFromBot: this.modalPopupResponse,
        fromRightDrawer: false
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }


  openConfirmationDialog(): void {
    if (!this.flag && this.flag !== undefined) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
        disableClose: true,
        data: {
          type: 0, options: ['English', 'Français']
        } // Pass your options array
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === "Français") {
          this.chatbotService.pref_lang_type = "fr";
          this.chatbotService.pref_lang__in_chat = true;
          this.translated.get('successfullMessages.LANGUAGE_PREFREANCE_FR').subscribe((translatedText: string) => {
            this.toastr.success(`${translatedText}`);
          });
        }
        else if (result === "English") {
          this.chatbotService.pref_lang_type = "en";
          this.chatbotService.pref_lang__in_chat = true;
          this.translated.get('successfullMessages.LANGUAGE_PREFREANCE_EN').subscribe((translatedText: string) => {
            this.toastr.success(`${translatedText}`);
          });
        }
      });
    }
    else {
      this.translated.get('errorMessages.LANGUAGE_ON_CHANGE_ERROR').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    }
  }

  ngOnInit(): void {
    this.profileImageSet();
    this.clearSubscription = this.stateService.clearDashboard$.subscribe(() => {
      this.clearElements();
    });

    /**Method Used to find desktop/ Mobile device */
    this.breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      // Get the screen width and height
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Determine if it's a mobile view based on device type and screen size
      if (result.matches || screenWidth <= 768) {
        this.isDesktop = false; // Apply mobile view
      } else {
        this.isDesktop = true; // Apply desktop view
      }
    });

    //Do not remove this code//
    /**Enabling chat input field when start new conversation without rating response */
    // this.stateService.disableInput$.subscribe(disableInput => {
    //   this.chatbotService.inputDisabled = disableInput;
    // });
    this.translateService.setLanguage(this.sessionStorage.get('language'));
    this.loaderSubscription = this.chatbotService.loaderState$.subscribe(
      (state: boolean) => {
        this.isLoader = state;
      }
    );
    this.stateService.tourStarted$.subscribe(() => {
      this.startTour(); // Call startTour when the event is emitted
    });
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }
  profileImageSet() {
    let userImage = this.sessionStorage.get('profile_picture');
    this.chatbotService.profilePictureUrl = userImage !== '' ? this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${userImage}`) : 'assets/noImage.png';
    this.userProfile = this.chatbotService.profilePictureUrl;
  }
  clearChatHistory() {
    this.chatbotService.clearChatHistory(); // Call the clearChatHistory method from the service
  }

  ngOnDestroy(): void {
    // Unsubscribe from the subscription
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  // Method to clear elements
  clearElements(): void {
    this.qaResponses = [];
  }

  onCloseModal(event: boolean) {
    this.disabledIcon = null;
    if (event) {
      this.chatbotService.feedbackIconDisabled = false;
      this.isUserRatingIconEnabled = true;
      this.showModalRating = false;
      this.chatbotService.inputDisabled = true;
    }
  }

  async callInitialChatAPI(payload: any) {
    this.botLoderType = 'boxLoader';
    try {
      await this.chatbotService.initialChatResponse(payload);
    } catch (error) {
    }
  }

  async sendMessage() {
    this.isIconDisabled(this.disabledIcon = '');
    this.botLoderType = 'barLoader';
    this.isLoader = true;
    if (this.userInput.trim() !== '' && this.userInput.length >= 2) {
      this.suggestions = [];
      this.imageKeys = [];
      this.imageUrls = [];
      this.profileImageSet();
      this.chatbotService.inputDisabled = true;
      this.stepWiseQuestion = this.userInput;
      this.chatbotService.botCurrentQuestion = this.userInput;
      let language: any = '';
      if (this.chatbotService.pref_lang__in_chat) {
        language = this.chatbotService.pref_lang_type
      } else {
        language = this.chatbotService.languageType
      }
      if (this.botType !== 'cibc') {
        /**commented similar question api call */
        let similarPayload = {
          conversationID: this.chatbotService.initialConversationID,
          question: this.userInput,
          bot_type: this.sessionStorage.get('bot_type'),
          language_type: language
        }
        this.chatbotService.getSimiliarQuestion(similarPayload).subscribe(
          (response) => {
            this.qaResponses = response?.similarQuestionResponse ? response?.similarQuestionResponse : response.cacheResponse;
            this.userInput = '';
            this.contentHeight = this.contentRef?.nativeElement?.scrollHeight;
          },
          (error) => {
            // this.toastr.error(error?.error.error);
          }
        );
      }
      this.userTypedInformation = this.userInput;

      let responsChat = await this.chatbotService.sendMessage(this.userInput, language, "stepwiseCallFalse");
      this.contentHeight = this.contentRef?.nativeElement?.scrollHeight;
      this.userInput = '';
      this.botCurrentResponse = responsChat;
      this.chatbotService.botCurrentResponse = responsChat;
      const feedbackMandatory = this.chatbotService?.botConfigurationValues?.enable_feedback === 'true';
      const responseTypes = ['small-talk', 'PII', 'language_mismatch_french', 'language_mismatch_english'];

      if (feedbackMandatory && responseTypes.includes(responsChat?.responseType)) {
        this.chatbotService.inputDisabled = false;
      }

      this.suggestions = [];
      this.showModalRating = false;
      let responseType;

      if (this.botCurrentResponse?.responseType === 'cache') {
        responseType = 'normal';
      } else if (this.botCurrentResponse?.responseType === 'sensitive-question') {
        responseType = 'sensitive';
      } else if (this.botCurrentResponse?.responseType === 'no-content' || this.botCurrentResponse?.responseType === 'not-answered') {
        responseType = 'no_content';
      } else if (this.botCurrentResponse?.responseType === 'cibc-questions') {
        responseType = 'normal';
      }

      const payloadFeedback = {
        "bot_type": this.sessionStorage.get('bot_type'),
        "response_type": responseType
      }
      this.chatbotService.getFeedbackList(payloadFeedback).subscribe((res: any) => {
        this.chatbotService.feedbackResponseFromAPI = res;
        const language = this.sessionStorage.get('language');
        const transformedData = res.map((item: any) => {
          let feedbackComment;

          if (language === 'en') {
            if (this.chatbotService.pref_lang_type === "fr") {
              feedbackComment = item.feedback_comment_french;
            } else {
              feedbackComment = item.feedback_comment_english;
            }
          } else if (language === 'fr') {
            if (this.chatbotService.pref_lang_type === "en") {
              feedbackComment = item.feedback_comment_english;
            } else {
              feedbackComment = item.feedback_comment_french;
            }
          } else {
            feedbackComment = item.feedback_comment;
          }

          feedbackComment = feedbackComment.charAt(0).toUpperCase() + feedbackComment.slice(1);

          return {
            value: feedbackComment,
            label: feedbackComment,
            additionalComments: item.additional_comments_required,
          };
        });

        this.chatbotService.ratingFeedbackComments = transformedData;
      }, (error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } else if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      })
      this.isLoader = false;
      this.chatbotService.feedbackIconDisabled = false;
      this.isAutoRatingIconEnabled = true;
      this.isUserRatingIconEnabled = true;
      this.chatbotService.cacheHyperlinkClicked = false;
      this.chatbotService.stepWiseHyperlinkClicked = false;
    } else {
      this.translated.get('errorMessages.MINIMUM_CHAR_CHECK_ERROR').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
      this.isLoader = false;
    }
    /**Image Naming Code in response */
    let imageData = this.chatbotService?.botRespponse?.images?.filter((item: any) => !this.isEmptyObject(item));
    imageData?.forEach((item: any) => {
      if (item && typeof item === 'object') {
        const key = Object.keys(item)[0];
        const url = item[key];
        if (key) {
          this.imageKeys.push(key);
          this.imageUrls.push(url);
        }
      }
    });

    // Set the initial key to display
    if (this.imageKeys.length > 0) {
      this.currentIndex = 0;
      this.previewUrl = this.imageUrls[this.currentIndex];
    }
  }

  // Getter for the current key
  get currentKey(): string {
    return this.imageKeys[this.currentIndex] || 'No Images available';
  }

  // Show the previous key
  showPreviousKey(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.imageKeys.length - 1; // Loop to last key
    }
    this.previewUrl = this.imageUrls[this.currentIndex];; // Reset preview when changing key
  }

  // Show the next key
  showNextKey(): void {
    if (this.currentIndex < this.imageKeys.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0; // Loop to first key
    }
    this.previewUrl = this.imageUrls[this.currentIndex];; // Reset preview when changing key
  }
  // Helper function to check if an object is empty
  isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.chatbotService.pref_lang_type || this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
  handlePositiveRatingClick(): void {
    if (this.isAutoRatingIconEnabled) {
      this.posstiveRate();
    }
  }

  handleUserRatingClick(): void {
    if (this.isUserRatingIconEnabled) {
      this.rating();
    }
  }
  onIconClick(icon: string): void {
    this.chatbotService.givenRating = icon;
    this.disabledIcon = icon;
  }

  isIconDisabled(icon: string): boolean {
    return this.disabledIcon === icon;
  }
  posstiveRate(): void {
    this.chatbotService.setModalLoader(true);
    let payload = {
      conversationID: this.chatbotService.initialConversationID,
      comment: this.sessionStorage.get('language') === 'en' ? "Fully Satisfied" : "Entièrement satisfait",
      rating: "5",
      additionalComment: "",
      bot_type: this.sessionStorage.get('bot_type'),
      language_type: this.sessionStorage.get('language')
    }
    this.chatbotService.feedbackIconDisabled = true;
    this.chatbotService.saveUserFeedback(payload).subscribe(
      (feedbackRes) => {
        if (feedbackRes) {
          this.chatbotService.setModalLoader(false);
          this.translated.get('successfullMessages.FEEDBACK_SAVE_SUCCESS').subscribe((translatedText: string) => {
            this.toastr.success(translatedText);
          });
          this.chatbotService.inputDisabled = false;
        }
      },
      (error: any) => {
        this.chatbotService.setModalLoader(false);
        this.chatbotService.inputDisabled = false;
        this.chatbotService.feedbackIconDisabled = false;
        if (error?.status === 519) {
          const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
            width: 'auto',
            disableClose: true,
            data: {
              isConversationTimout: true,
              conversationTimoutMessage: error?.error?.error
            },
          });

          dialogRef.afterClosed().subscribe(result => {
          });
        } else {
          // this.toastr.error(error?.error?.error);
        }

      }
    );
  }

  rating(): void {
    this.chatbotService.feedbackIconDisabled = true;
    this.isUserRatingIconEnabled = false;
    this.showModalRating = true;
    this.chatbotService.inputDisabled = false;
  }

  handleModalPopup(botReponses: any): void {
    this.modalPopupResponse = botReponses;
    this.openDialog('0ms', '0ms');
  }

  handleCacheHyperlink(): void {
    this.chatbotService.cacheHyperlinkClicked = true;
    let language: any = '';
    if (this.chatbotService.pref_lang__in_chat) {
      language = this.chatbotService.pref_lang_type
    } else {
      language = this.chatbotService.languageType
    }
    let conversationID = '';
    if (this.botCurrentResponse?.conversationID !== undefined) {
      conversationID = this.botCurrentResponse?.conversationID;
    } else if (this.chatbotService?.botCurrentResponse.conversationID !== undefined) {
      conversationID = this.chatbotService?.botCurrentResponse?.conversationID;
    } else if (this.chatbotService.botRespponse.conversationID) {
      conversationID = this.chatbotService.botRespponse.conversationID;
    }
    const toCachePayload = {
      'conversationID': conversationID,
      'bot_type': this.sessionStorage.get('bot_type'),
      "language_type": language
    }
    this.chatbotService.responseToAddToCache(toCachePayload).subscribe((toCacheResponse: any) => {
      if (toCacheResponse.message === "Similar questions found in cache") {
        this.translated.get('successfullMessages.TO_CACHE_SIMILAR_FOUND').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
      } else if (toCacheResponse.message === "Question added to cache") {
        this.translated.get('successfullMessages.CACHEQ&A_INDEXD_SUCCESS').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
      }
    }, (error: any) => {
      this.translated.get('errorMessages.REQUIRED_MISSING_FIELDS').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    })
  }

  handleStepwiseHyperlink() {
    this.isLoader = true;
    this.chatbotService.stepWiseHyperlinkClicked = true;
    let language: any = '';
    if (this.chatbotService.pref_lang__in_chat) {
      language = this.chatbotService.pref_lang_type
    } else {
      language = this.chatbotService.languageType
    }
    let currentQuestion = '';
    if (this.stepWiseQuestion) {
      currentQuestion = this.stepWiseQuestion;
    } else if (this.chatbotService?.botCurrentQuestion) {
      currentQuestion = this.chatbotService?.botCurrentQuestion
    }
    this.chatbotService.sendMessage(currentQuestion, language, "stepwiseCallTrue").then(responsChat => {
      this.stepWiseQuestion = '';
      this.isLoader = false;
      if (this.chatbotService.feedbackIconDisabled) {
        this.chatbotService.feedbackIconDisabled = !this.chatbotService.feedbackIconDisabled;
      }
    }).catch(error => {
      // this.toastr.error(error?.error?.error)
    });
  }
  selectSuggestion(suggestion: string): void {
    this.userInput = suggestion;
    this.showSuggestions = false;
  }
  /**Auto Completed For User Question */
  changingInput(value: string): void {
    const words = value.trim().split(/\s+/);
    if (words.length > 2 && value !== '' && this.botType !== 'cibc') {
      let payload = {
        question: value,
        bot_type: this.sessionStorage.get('bot_type'),
        language_type: this.sessionStorage.get('language')
      }
      // Cancel method for previous API request raised by next latter
      if (this.autoCompleteSubscription) {
        this.autoCompleteSubscription.unsubscribe();
      }

      this.autoCompleteSubscription = this.chatbotService.getQuestionAutoComplete(payload).pipe(
        switchMap(automCompleteResponse => {
          this.suggestions = automCompleteResponse?.suggestions.slice(0, 6);
          return of(null); // Return a completed observable
        }),
        catchError(error => {
          this.suggestions = []; // Clear suggestions on error
          return of(null); // Return a completed observable
        })
      ).subscribe(() => {
        this.showSuggestions = true;
      });
    } else {
      this.showSuggestions = false;
      this.suggestions = [];
    }
  }

  handleDisclosure() {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      disableClose: true,
      width: 'auto',
      data: {
        isValidDisclosure: true,
        transaction_type: this.chatbotService?.botRespponse?.disclosure_details?.transaction_type,
        transaction_type_content: this.chatbotService?.botRespponse?.disclosure_details?.transaction_type_content,
        transaction_disclosures: this.chatbotService?.botRespponse?.disclosure_details?.transaction_disclosures,
        transaction_notes: this.chatbotService?.botRespponse?.disclosure_details?.transaction_notes
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  imagePreview(event: MouseEvent, url: any) {
    event.preventDefault();
    const regex = /view_image/;
    if (regex.test(url)) {
      this.downloadFile(url).subscribe(
        response => {
          const contentType = response.type;
          const blob = new Blob([response], { type: contentType });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        },
        error => {
          this.translated.get('errorMessages.IMAGE_PREVIEW_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
        }
      );
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob', // Specify responseType as blob
      headers: {
        Authorization: `Bearer ${this.sessionStorage.get('appTo')}`
      }
    });
  }

  hasValues(obj: object | null | undefined): boolean {
    return obj ? Object.keys(obj).length > 0 : false;
  }
  onInputClick(): void {
    if (this.chatbotService.inputDisabled) {
      const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
        width: 'auto',
        disableClose: true,
        data: {
          isRatingRquiredClicked: true,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    } else {
      // Regular input click handling logic
    }
  }
  // Method to toggle dropdown visibility
  toggleDropdown(): void {
    this.IeIIsDropdownVisible = !this.IeIIsDropdownVisible;
  }

  // Method to select an option and hide the dropdown
  selectDropdownOption(option: any): void {
    this.chatbotService.iEIisTypeValues = option.name;
    this.IeIIsDropdownVisible = false;
  }
}

