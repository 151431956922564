<div class="page-containter" fxLayout="column"
    [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div *ngIf="!isNewContent">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20" fxLayout.xs="column"
            fxLayoutAlign.xs="start stretch">
            <button mat-raised-button class="mui-button" (click)="newConent()">{{'sideNav.ADD_CONTENT'
                | translate}}</button>
            <mat-form-field appearance="fill">
                <mat-label>{{'sideNav.SEARCH'| translate}}</mat-label>
                <input matInput #searchInput [(ngModel)]="searchQuery"
                    placeholder="{{'devConfig.ENTER_FOR'| translate}}" (keydown.enter)="searchTableData()">
                <button mat-icon-button matSuffix (click)="searchTableData()">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button matSuffix (click)="clearSearch()">
                    <mat-icon>clear</mat-icon>
                </button>

            </mat-form-field>
        </div>
        <div class="table-content-fit-page">
            <table class="custom-tables" *ngIf="data.length > 0; else loaderSection">
                <thead>
                    <tr>
                        <th>{{'sideNav.CONTENT_NAME' | translate}}</th>
                        <th>{{'contentMgmt.UPLOADED_BY' | translate}}</th>
                        <th>{{'contentMgmt.STATUS' | translate}}</th>
                        <th>{{'contentMgmt.INDEXED_DATE_TIME' | translate}}</th>
                        <th>{{'sideNav.LANGUAGE_TYPE'| translate}}</th>
                        <th>{{'contentMgmt.BOOSTING_SCORE'| translate}}</th>
                        <th>{{'devConfig.ACTION' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr *ngFor="let row of data; let index = index" [class.even-row]="index % 2 === 0"
                            [class.odd-row]="index % 2 !== 0">
                            <td class="cursor">
                                <a (click)="fileDownload(row)">{{ row.content_name }}</a>
                            </td>
                            <td>{{ row.username }}</td>
                            <td>{{ row.status }}</td>
                            <td class="text-nowrap">{{ dateFormatService.convertUtcToLocalSlash(row.date) }}</td>
                            <td>{{ row.language_type === 'en' ? 'English' : row.language_type === 'fr' ? 'French' :
                                row.language_type }}</td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Score</mat-label>
                                    <mat-select [(value)]="row.boost_score" [disabled]="!editMode[index]"
                                        (selectionChange)="handleBoostScoreChange($event, index)">
                                        <mat-option *ngFor="let number of numbersArray" [value]="number">
                                            {{ number }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <div class="action-even-space">
                                    <ng-container
                                        *ngIf="editMode[index]; then editModeEnabled else editModeDisabled"></ng-container>
                                    <ng-template #editModeEnabled>
                                        <button *ngIf="editModeEnabled" mat-button class="mui-button-clear"
                                            (click)="cancelEditMode(index)">{{'devConfig.CANCEL' |
                                            translate}}</button>
                                        <button mat-icon-button (click)="handleSaveChanges(row, index)">
                                            <img src="assets/icons8-save-30.png" class="icon-button cursor" height="5"
                                                alt="Save Icon">
                                        </button>
                                    </ng-template>
                                    <ng-template #editModeDisabled>
                                        <button mat-icon-button (click)="handleEditClick(row, index)"
                                            [disabled]="row.status !== 'Completed'">
                                            <img src="assets/pen 1.svg" class="icon-button cursor"
                                                [class.disabled]="row.status !== 'Completed'" height="5"
                                                alt="Edit Icon">
                                        </button>
                                    </ng-template>
                                    <span>
                                        <button mat-icon-button (click)="handleDelete(row, index)"
                                            [disabled]="row.status === 'Under Process'">
                                            <img src="assets/trash 1-black.svg" class="icon-button cursor"
                                                [class.disabled]="row.status === 'Under Process'" height="5"
                                                alt="Delete">
                                        </button>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <ng-template #loaderSection>
                <div class="loader-center-align">
                    <div *ngIf="!isLoader">
                        {{'contentMgmt.NO_RECORD_FOUND' | translate}}
                    </div>
                </div>
                <div class="loader-center-align loader-container" *ngIf="isLoader">
                    <app-loader [botLoderType]="botLoderType"></app-loader>
                </div>
            </ng-template>
        </div>
        <div class="pagination" *ngIf="data && data.length > 0 && searchQuery ===''">
            <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
                <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
            </button>
            <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
                <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
            </button>
            <span>{{ pageNumber }} / {{ totalTablePage }}</span>
            <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage"
                [disabled]="pageNumber === totalTablePage">
                <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
            </button>
            <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage"
                [disabled]="pageNumber === totalTablePage">
                <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
            </button>
        </div>
    </div>
    <div class="padding10 new-content-fit-page" *ngIf="isNewContent">
        <h3>{{'sideNav.ADD_CONTENT' | translate}}</h3>
        <label>{{'sideNav.CONTENT_TYPE' | translate}}:</label>
        <div>
            <mat-radio-group class="custom-radio-group" [(ngModel)]="selectedOption" (change)="handleRadioChange()">
                <mat-radio-button value="file">{{'sideNav.FILE' | translate}}</mat-radio-button>
                <mat-radio-button value="website">{{'sideNav.WEBSITE' | translate}}</mat-radio-button>
                <mat-radio-button value="html">{{'sideNav.HTML' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="selectedOption === 'file'">
            <div *ngIf="chatbotService?.botConfigurationValues?.enable_disclosure ==='true'">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20">
                    <label>{{'disclosure.DISCLOSURE' | translate}}:</label>
                    <div class="toggle">
                        <input type="checkbox" id="toggle" [(ngModel)]="isDisclosure" class="toggle-input">
                        <label for="toggle" class="toggle-label">
                            <span class="toggle-on">ON</span>
                            <span class="toggle-off">OFF</span>
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="chatbotService?.botConfigurationValues?.enable_image ==='true'">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20">
                    <label>{{'disclosure.IMG_REQUIRED' | translate}}:</label>
                    <div class="toggle">
                        <input type="checkbox" id="img-toggle" [(ngModel)]="imageRequired" class="toggle-input">
                        <label for="img-toggle" class="toggle-label">
                            <span class="toggle-on">ON</span>
                            <span class="toggle-off">OFF</span>
                        </label>
                    </div>
                </div>
            </div>
            <div fxLayout="row" *ngIf="isDisclosure" fxLayoutAlign="center center" class=" font-size12">
                *{{'disclosure.DIS_NOTES' | translate}}</div>
            <div fxLayout="row" *ngIf="!isDisclosure" fxLayoutAlign="center center">
                *{{'disclosure.DIS_NOTES_DOC' | translate}}</div>
            <div class="upload-padding">
                <div class="file-upload">
                    <div class="drop-area" (drop)="handleDrop($event)" (dragover)="preventDefault($event)"
                        (dragenter)="preventDefault($event)" (dragleave)="preventDefault($event)">
                        <ng-container *ngIf="selectedFile; else fileNotSelected">
                            <div class="selected-file">
                                <p>{{ selectedFile.name }}</p>
                                <p>{{ formatSize(selectedFile.size) }}</p>
                                <p (click)="handleDeleteFile()" class="cursor">
                                    <img src="assets/trash 1-white.svg" alt="Remove File" class="icon-black" />
                                    {{'sideNav.REMOVE' |
                                    translate}}
                                </p>
                            </div>
                        </ng-container>
                        <ng-template #fileNotSelected>
                            <div>
                                <div class="head-center">
                                    <img src="assets/file-upload.svg" height="50" alt="Upload Icon" />
                                </div>
                                <div class="head-center" (click)="triggerFileInput()">
                                    <label for="file-input" class="head-center">
                                        {{'sideNav.DRAG_DROP_TEXT' | translate}} <span
                                            class="choose-file">{{'sideNav.CHOOSE_FILE' | translate}}</span>
                                    </label>
                                    <input type="file" id="file-input" [accept]="isDisclosure ? '.xlsx' : '.pdf,.docx'"
                                        (change)="handleFileChange($event)" class="input-file-style" />
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedFile?.name">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'sideNav.FILE_TYPE' | translate}}</mat-label>
                    <mat-select [(ngModel)]="fileType" [disabled]="fileUploaded" required>
                        <mat-option *ngFor="let type of fileTypes" [value]="type.value">{{ type.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="selectedOption === 'website'">
            <mat-form-field style="width: 100%;">
                <mat-label>{{'sideNav.Website_URL' | translate}}</mat-label>
                <input matInput placeholder="{{'sideNav.Website_URL' | translate}}" [(ngModel)]="websiteUrl"
                    (input)="updateExcludedValues()" required>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="20">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'sideNav.Enter_Excluded_Value' | translate}}</mat-label>
                    <input matInput [(ngModel)]="newExcludedValue">
                </mat-form-field>
                <button matTooltip="{{'sideNav.add_Excluded_Value' | translate}}" mat-icon-button
                    (click)="addExcludedValue()"><mat-icon
                        (click)="showFileName()">add_circle_outline</mat-icon></button>
            </div>
            <div *ngIf="excludedValues.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'sideNav.EXCLUDED_URL' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedExcludedValue" multiple disabled="true">
                        <mat-option *ngFor="let value of excludedValues" [value]="value">{{ value }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="selectedOption === 'html'">
            <mat-form-field style="width: 100%;">
                <mat-label>{{'sideNav.HTML_LINK' | translate}}</mat-label>
                <input matInput [(ngModel)]="htmlLink" placeholder="{{'sideNav.Enter_HTML_Link'| translate}}" required>
            </mat-form-field>
        </div>
        <div *ngIf="selectedOption === 'html' || selectedOption === 'website' || selectedOption === 'file'">
            <mat-form-field style="width: 100%;">
                <mat-label>{{'sideNav.LANGUAGE_TYPE' | translate}}</mat-label>
                <mat-select [(ngModel)]="languageType" required>
                    <mat-option *ngFor="let lang of languageTypes" [value]="lang">{{ lang }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="selectedOption === 'html' || selectedOption === 'website' || selectedOption === 'file'">
            <mat-form-field style="width: 100%;">
                <mat-label>{{'contentMgmt.BOOSTING_SCORE' | translate}}</mat-label>
                <mat-select [(ngModel)]="boostScore" required>
                    <mat-option *ngFor="let score of numbersArray" [value]="score">{{ score }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="selectedOption === 'html' || selectedOption === 'website' || selectedOption === 'file'">
            <mat-form-field style="width: 100%;">
                <mat-label>{{'sideNav.CONTENT_NAME' | translate}}</mat-label>
                <input matInput [(ngModel)]="contentName" (ngModelChange)="replaceSpaces($event)"
                    placeholder="{{'sideNav.Enter_Content_Name'|translate}}" required>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20">
            <button mat-raised-button class="mui-button-clear" (click)="cancelContent()">{{'cache.CANCEL' |
                translate}}</button>
            <button mat-raised-button class="mui-button" [disabled]="disableSave" (click)="addContent()">{{'rating.SAVE'
                | translate}}</button>
        </div>
    </div>
    <div class="modal-backdrop" *ngIf="isModalLoader">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>