import { Component, OnInit } from '@angular/core';
import { ContentManagementService } from '../services/content-management.service';
import { TranslationService } from '../services/translation-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmationDialogComponentComponent } from '../confirmation-dialog-component/confirmation-dialog-component.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../services/localStorageService';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DateFormatService } from '../pipe/date-time-localize';
import { ChatbotService } from '../services/chat.service';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { StateService } from "../services/shared-object.service";
@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: ['./cache.component.scss']
})
export class CacheComponent implements OnInit {
  searchQuery: string = '';
  data: any = [];
  pageNumber: number = 1;
  pageSize: number = 15;
  translate: any;
  isLoader = false;
  botLoderType = 'boxLoader';
  totalTablePage: number = 0;
  showEditSection: boolean = false;
  displayedColumns: any[] = ['question', 'generated_response', 'added_by', 'date_time', 'action'];
  selectedFile: File[] = [];
  disclosuresInfo: string = '';
  selectedTransaction: any = null;
  dataDisclosure: any[] = [];
  imageUpload: string = 'no';
  searchDisclosure: string = 'no';
  isModalLoader: boolean = false;
  question: string = '';
  response: string = '';
  botType: string = '';
  selectedTab: number = 0;
  languageSelected: string = ''
  language: string = 'en';
  languageTypeOptions = [
    { label: "English", value: "en" },
    { label: "Français", value: "fr" }
  ];
  isExpandCollapse: boolean = true;
  constructor(private contentMgmtService: ContentManagementService,
    translateService: TranslationService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private sessionStorage: LocalStorageService,
    private toaster: ToastrService,
    private router: Router,
    public dateFormatService: DateFormatService,
    public chatService: ChatbotService,
    private translated: TranslateService,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private http: HttpClient,
    private stateService: StateService,
  ) {
    this.translate = translateService;
  }

  ngOnInit(): void {
    this.selectedTab = 0;
    this.languageSelected = 'en'
    this.getAllCache(1, 'en');
    this.botType = this.sessionStorage.get('bot_type');
    this.chatService.getAppBotConfiguration();
    if (this.chatService?.botConfigurationValues?.enable_image === 'true') {
      const imagesIndex = 2; // The index where 'images' should appear
      this.displayedColumns.splice(imagesIndex, 0, 'images');
    }
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.languageSelected = 'en';
      this.pageNumber = 1;
      this.getAllCache(this.pageNumber, 'en')
    } else if (event.index === 1) {
      this.languageSelected = 'fr';
      this.pageNumber = 1;
      this.getAllCache(this.pageNumber, 'fr')
    }
  }

  getAllCache(pageNumber: any, language: any) {
    this.data = [];
    this.isLoader = true;
    this.contentMgmtService.getAllCache(pageNumber, this.pageSize, language).subscribe((cacheResponse: any) => {
      this.isLoader = false;
      this.totalTablePage = cacheResponse.total_page_number;
      this.data = cacheResponse?.data;
    }, (error: any) => {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    })
  }

  addNewRow() {
    this.showEditSection = true;
  }

  searchTableData() {
    if (this.searchQuery !== "") {
      this.isLoader = true;
      const payloadSearch = { "search": this.searchQuery, "bot_type": this.sessionStorage.get('bot_type'), "language_type": this.sessionStorage.get('language') }
      this.contentMgmtService.performCacheAction('search', payloadSearch).subscribe((response: any) => {
        this.isLoader = false;
        this.data = response.data;
        if (response.data.length === 0) {
          this.translated.get('errorMessages.SEARCH_NOT_FOUND').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
        }
      }, (error: any) => {
        // this.toaster.error(error?.error?.error);
      })
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }

  clearSearch() {
    if (this.searchQuery !== "") {
      this.searchQuery = "";
      this.getAllCache(1, 'en');
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.info(translatedText);
      });
    }
  }

  handleDelete(item: any, i: number) {
    this.openConfirmationDialog(item)
  }

  openConfirmationDialog(selectedRow: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const messageDelete: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL}&nbsp;&nbsp;&nbsp;${selectedRow?.question}`);
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1,
        message: messageDelete,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isModalLoader = true;
      if (result) {
        const cacheDeletePayload = {
          "doc_id": selectedRow._id,
          "bot_type": this.sessionStorage.get('bot_type'),
          "language_type": this.languageSelected
        }
        this.contentMgmtService.performCacheAction('delete', cacheDeletePayload).subscribe((response: any) => {
          this.isModalLoader = false;
          this.translated.get('successfullMessages.CACHE_DELETE_MESSAGE').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.languageSelected = 'en';
          this.selectedTab = 0;
          this.getAllCache(1, 'en');
          this.pageNumber = 1;
        }, (error: any) => {
          this.isModalLoader = false;
          this.translated.get('errorMessages.DELETED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.languageSelected = 'en';
          this.selectedTab = 0;
          this.getAllCache(1, 'en');
        });
      } else {
        this.isModalLoader = false;
      }
    });
  };

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.getAllCache(pageNumber, this.languageSelected)
  }

  onCancel() {
    try {
      this.showEditSection = false;
    } catch (error) {
    }
  }

  onSave() {
    if (this.question !== "" && this.response !== "") {
      const payloadSave = new FormData();
      payloadSave.append("question", this.question);
      payloadSave.append("answer", this.response);
      payloadSave.append("bot_type", this.sessionStorage.get('bot_type'));
      payloadSave.append("language_type", this.language);
      payloadSave.append("added_by", this.sessionStorage.get('username'));
      if (this.selectedFile.length) {
        payloadSave.append("image_count", this.selectedFile.length.toString());
      }
      if (this.selectedFile.length > 0) {
        this.selectedFile.forEach((file, index) => {
          payloadSave.append(`images[${index}]`, file);
        });
      }
      this.isModalLoader = true;
      payloadSave.append("disclosure_details", JSON.stringify(this.selectedTransaction ? this.selectedTransaction : {}));
      this.contentMgmtService.performCacheAction('save', payloadSave).subscribe(
        (response: any) => {
          if (response) {
            this.showEditSection = false;
            this.translated.get('successfullMessages.CACHEQ&A_INDEXD_SUCCESS').subscribe((translatedText: string) => {
              this.toaster.success(translatedText);
            });
            this.getAllCache(1, 'en');
            this.question = '';
            this.response = '';
            this.pageNumber = 1;
            this.disclosuresInfo = '';
            this.selectedFile = [];
            this.selectedTransaction = null;
            this.isModalLoader = false;
          }
        },
        (error: any) => {
          this.isModalLoader = false;
          if (error.status === 406) {
            this.translated.get('errorMessages.QUESTION_EXIST_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
          } else if (error.status === 400) {
            if (error.error.error.includes('Input validation failed. Ensure all input values are in fr.')) {
              this.translated.get('errorMessages.CACHE_FR_ERROR').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
            } if (error.error.error.includes('Input validation failed. Ensure all input values are in en.')) {
              this.translated.get('errorMessages.CACHE_EN_ERROR').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
            }
            else if(error.error.error.includes("Invalid Image Format")) {
              this.translated.get('errorMessages.INVALID_IMG_FORMAT').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
            }
          }
        }
      );
    } else {
      this.translated.get('errorMessages.CACHE_INPUT_REQUIRED').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }
  onFileSelected(fileInput: HTMLInputElement): void {
    if (fileInput.files) {
      this.selectedFile = Array.from(fileInput.files);
    }
  }
  removeFile(index: number) {
    this.selectedFile.splice(index, 1);
  }

  fetchDisclosure(disclosureValue: any) {
    if (disclosureValue) {
      const payload = new FormData();
      payload.append('bot_type', this.sessionStorage.get('bot_type'))
      payload.append('language_type', this.sessionStorage.get('language'))
      payload.append('search_text', disclosureValue)
      this.isModalLoader = true;
      this.chatService.getDisclosureDetails(payload).subscribe((res: any) => {
        this.isModalLoader = false;
        this.dataDisclosure = res.data;
        const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
          width: 'auto',
          disableClose: true,
          data: {
            isDisclosureFrmCache: true,
            disclosureFromCache: res?.data
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selectedTransaction = result?._value[0];
          this.disclosuresInfo = result?._value[0]?.transaction_type

        });
      }, ((error: any) => {
        // this.toaster.error(error?.error?.error)
      }))
    }
  }


  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
  getImageKeys(row: any): any[] {
    if (row.images && row.images.length > 0) {
      // Use Set to remove duplicates
      const keys = new Set(row.images.flatMap((image: any) => Object.keys(image)));
      return Array.from(keys);
    }
    return [];
  }

  previewImages(event: MouseEvent, row: any): void {
    const imageKeys = this.getImageKeys(row);
    if (imageKeys.length > 0) {
      event.stopPropagation();
      // Preview the images, you can call your existing image preview logic here.
      const firstImageKey = imageKeys[0];
      this.previewImage(event, row.images, firstImageKey); // Adjust if you need to handle multiple images
    }
  }

  previewImage(event: MouseEvent, images: any[], key: string) {
    event.preventDefault();
    const imageUrl = images.find(image => image[key])[key];
    const regex = /view_image/;
    this.isModalLoader = true;
    if (regex.test(imageUrl)) {
      this.downloadFile(imageUrl).subscribe(
        response => {
          const contentType = response.type;
          const blob = new Blob([response], { type: contentType });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          this.isModalLoader = false;
        },
        error => {
          this.isModalLoader = false;
          this.translated.get('errorMessages.IMAGE_PREVIEW_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
        }
      );
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob', // Specify responseType as blob
      headers: {
        Authorization: `Bearer ${this.sessionStorage.get('appTo')}`
      }
    });
  }
  showPopOver(content: string) {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        isCacheUserResponse: true,
        title: 'CacheQnA',
        cacheUserResponse: content
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onRowClick(row: string) {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: '100%',
      maxWidth: 'none',
      disableClose: true,
      data: {
        isCacheQnAFullRow: true,
        cacheQnARowDetails: row
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  isSaveButtonDisabled(): boolean {
    const isQuestionAndResponseValid = this.question !== '' && this.response !== '';
    const isImageUploadValid = this.imageUpload === 'yes' ? this.selectedFile.length > 0 : true;
    return (!isQuestionAndResponseValid || !isImageUploadValid);
  }
  replaceDivsWithParagraphs(input: string): string {
    if (!input) return input; // Return early if the input is null or undefined
    // Regex to remove all <div>, </div>, <p>, and </p> tags
    return input.replace(/<\/?div>/g, '').replace(/<\/?p>/g, '');
  }
}
