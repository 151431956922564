import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ValidatorServiceService } from '../../services/validator-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { LocalStorageService } from 'src/app/services/localStorageService';
import { DisclousrePopupModalComponent } from 'src/app/popup-modal/popup-modal.component';
import { ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmationDialogComponentComponent } from 'src/app/confirmation-dialog-component/confirmation-dialog-component.component';
import { TranslateServiceSpecifiedLabelsService } from 'src/app/services/translate-service-specified-labels.service';

interface ColumnDefinition {
  key: string;
  header: string;
}

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MatTableComponent implements OnInit, OnChanges {
  @Input() columns: ColumnDefinition[] = [];
  @Input() data: any[] = [];
  @Input() pageNumber: any;
  @Input() totalTablePage: any;
  @Output() usernameClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() compareClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterQuery: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatefavorite: EventEmitter<any> = new EventEmitter<any>();
  dataSource = new MatTableDataSource<any>([]);
  displayedColumnsKeys: any[] = [];
  dataSourceValidation: any[] = [];
  expandedElement: any | null;
  displayedColumns: string[] = [
    'question', 'context_recall', 'context_precision', 'faithfulness',
    'answer_relevancy', 'answer_correctness', 'eye_icon'
  ];
  currentRecordSelected: any;
  selection = new SelectionModel<any>(true, []);
  selectedCompareRunIDS: any[] = [];
  isLoader: boolean = false;
  botLoderType = 'boxLoader';
  selectedCompareData: any[] = [];
  fileTypes = ['db', 'xlsx', 'csv'];
  selectedFileTypes: string[] = [];
  filterHasValues: boolean = false;
  compareButtonDisabled = true;
  uniqueFileTypes: string[] = [];
  uniqueTestSizes: string[] = [];
  uniqueFileNames: string[] = [];
  uniqueFavourite: string[] = [];
  isYellow: boolean = false;
  filterModel: { [key: string]: any[] } = {};
  filterValues: any = {
    fileType: [],
    fileName: [],
    testSize: [],
    favourite: []
  };
  isModalLoader: boolean = false;
  isDropdownOpen: { [key: string]: boolean } = {};
  popoverStyles: { top: string; left: string } = { top: '0px', left: '0px' };
  constructor(private validator: ValidatorServiceService, private dialog: MatDialog, private toaster: ToastrService, private router: Router, private sessionStorageData: LocalStorageService, private cdr: ChangeDetectorRef, private translated: TranslateService, private sanitizer: DomSanitizer, private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService) { }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumnsKeys = [...this.columns.map(col => col.key), 'expand'];

    this.checkCompareButtonState();
    this.populateUniqueValues();

  }

  toggleDropdown(key: string, event: Event): void {
    this.isDropdownOpen[key] = !this.isDropdownOpen[key];
    if (this.isDropdownOpen[key]) {
      const iconElement = document.getElementById(`icon-${key}`);
      if (iconElement) {
        const rect = iconElement.getBoundingClientRect();
        this.popoverStyles = {
          top: `${rect.bottom + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`
        };
      }
    } else {
      this.popoverStyles = { top: '0px', left: '0px' };
    }
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event): void {
    this.isDropdownOpen = {};
  }

  populateUniqueValues() {
    this.uniqueFileTypes = [...new Set(this.data.map(item => item.fileType))];
    this.uniqueTestSizes = [...new Set(this.data.map(item => item.testSize))];
    this.uniqueFileNames = [...new Set(this.data.map(item => item.fileName))];
    this.uniqueFavourite = ['Favourite', 'Unfavourite'];;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.dataSource) {
      this.dataSource.data = this.data;
    }
    if (changes['columns']) {
      this.displayedColumnsKeys = [...this.columns.map(col => col.key), 'expand']
    }
  }

  onRunIDClick(selectedRow: any) {
    this.isLoader = true;
    this.currentRecordSelected = selectedRow;
    const payloadDetails = { "user_name": selectedRow.username, "run_id": selectedRow.runid }
    this.validator.getValidationDetailInfo(payloadDetails).subscribe((res: any) => {
      this.isLoader = false;
      this.dataSourceValidation = res;
    }, (error: any) => {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        this.sessionStorageData.clear();
      }
    })
  }

  toggleRow(row: any) {
    this.dataSourceValidation = this.data;
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  get isDataSourceEmpty(): boolean {
    return this.dataSource?.data?.length === 0;
  }

  onEyeIconClick(index: number) {
    // this.usernameClick.emit(this.currentRecordSelected);
    this.usernameClick.emit({ currentRecordSelected: this.currentRecordSelected, index: index });
  }

  onPageChange(pageNumber: number) {
    this.onPageChangeEvent.emit(pageNumber);
  }
  onDelete(element: any) {
    this.openConfirmationDialog(element)
  }
  openConfirmationDialog(element: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const DELETE_CONFIRM_FOLLOWING = this.translateLabel('DELETE_CONFIRM_FOLLOWING').translation;
    const DELETE_CONFIRM_RUNID = this.translateLabel('DELETE_CONFIRM_RUNID').translation;
    const DELETE_CONFIRM_AND = this.translateLabel('DELETE_CONFIRM_AND').translation;
    const DELETE_CONFIRM_FILE_TYPE = this.translateLabel('DELETE_CONFIRM_FILE_TYPE').translation;
    const DELETE_CONFIRM_FILE_NAME = this.translateLabel('DELETE_CONFIRM_FILE_NAME').translation;
    const messageDelete: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL} </br>${DELETE_CONFIRM_FOLLOWING}
    </br>${DELETE_CONFIRM_FILE_NAME}: ${element?.fileName}
    </br>${DELETE_CONFIRM_RUNID} : ${element?.runid}
    </br>${DELETE_CONFIRM_AND}
    </br>${DELETE_CONFIRM_FILE_TYPE} :${element?.fileType}
`);
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1,
        message: messageDelete,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isModalLoader = true;
      if (result) {
        const payload = { "run_id": element?.runid, "bot_type": this.sessionStorageData.get('bot_type'), "file_name": element?.fileName, "file_type": element?.fileType }
        this.validator.deleteValidation(payload).subscribe((res: any) => {
          this.usernameClick.emit({ currentRecordSelected: res });
          this.isModalLoader = false;
        }, (error: any) => {
          this.isModalLoader = false;
          if (error.status === 500) {
            this.translated.get('errorMessages.DELETED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
          }
        })
      }
    });
  };
  compareData() {
    try {
      this.compareClick.emit({ data: this.selectedCompareRunIDS, selectedAllData: this.selectedCompareData })
    } catch (error: any) {
      console.error("Error Message:", error.message);
    }
  }
  enableCompare(): boolean {
    const validation = this.validateFileTypesWithRunId(this.selectedCompareData);
    return validation.isValid;
  }
  checkCompareButtonState() {
    this.compareButtonDisabled = this.selectedCompareRunIDS.length <= 1 || !this.enableCompare();
  }
  checkFileTypes(data: any[]) {
    const result = this.validateFileTypesWithRunId(data);

    if (!result.isValid) {
      let errorMessage = '';
      if (result.runIds.length > 0) {
        errorMessage += `File types are not the same for run IDs: ${result.runIds.join(', ')}. `;
      }
      if (result.testSizeMismatchRunIds.length > 0) {
        errorMessage += `Test sizes are not the same for run IDs: ${result.testSizeMismatchRunIds.join(', ')}. `;
      }
      if (result.fileNameMismatchRunIds.length > 0) {
        errorMessage += `File names are not the same for run IDs: ${result.fileNameMismatchRunIds.join(', ')}. `;
      }
      errorMessage += 'Please choose the same file type and test size to compare.';
      return errorMessage;
    } else {
      return "All file types and test sizes are the same";
    }
  }

  validateFileTypesWithRunId(data: any[]): { isValid: boolean, runIds: string[], testSizeMismatchRunIds: string[], fileNameMismatchRunIds: string[] } {
    if (!data || data.length === 0) return { isValid: true, runIds: [], testSizeMismatchRunIds: [], fileNameMismatchRunIds: [] };

    const firstFileType = data[0].fileType;
    const firstTestSize = data[0].testSize;
    const firstFileName = data[0].fileName;
    const mismatchedRunIds = [];
    const testSizeMismatchRunIds = [];
    const fileNameMismatchRunIds = [];

    // Check for fileType mismatch
    for (let i = 1; i < data.length; i++) {
      if (data[i].fileType !== firstFileType) {
        mismatchedRunIds.push(data[i].runid);
      }
    }

    // If fileType is 'db', check for testSize mismatch
    if (firstFileType === 'db') {
      for (let i = 1; i < data.length; i++) {
        if (data[i].testSize !== firstTestSize) {
          testSizeMismatchRunIds.push(data[i].runid);
        }
      }
    } else if (['csv', 'xlsx'].includes(firstFileType)) {
      for (let i = 1; i < data.length; i++) {
        if (data[i].fileName !== firstFileName) {
          fileNameMismatchRunIds.push(data[i].runid);
        }
      }
    }

    return {
      isValid: mismatchedRunIds.length === 0 && testSizeMismatchRunIds.length === 0 && fileNameMismatchRunIds.length === 0,
      runIds: mismatchedRunIds,
      testSizeMismatchRunIds,
      fileNameMismatchRunIds
    };
  }

  onCheckboxChange(event: any, row: any) {
    if (event.checked) {
      this.toggleSelection(row, true);
    } else {
      this.toggleSelection(row, false);
    }
    this.checkCompareButtonState();
  }

  isSelected(row: any): boolean {
    return this.selectedCompareRunIDS.indexOf(row.runid) !== -1;
  }

  toggleSelection(row: any, isChecked: boolean) {
    if (isChecked) {
      const potentialSelection = [...this.selectedCompareData, row];
      const result = this.checkFileTypes(potentialSelection);
      if (result === "All file types and test sizes are the same") {
        this.selectedCompareRunIDS.push(row.runid);
        this.selectedCompareData.push(row);
      } else {
        this.selectedCompareRunIDS.push(row.runid);
        const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
          width: 'auto',
          disableClose: true,
          data: {
            isMismatchField: true,
            messageMismatchText: result
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          this.checkCompareButtonState();
          this.clearAllSelections();
        });
      }
    } else {
      const index = this.selectedCompareRunIDS.indexOf(row.runid);
      if (index !== -1) {
        this.selectedCompareRunIDS.splice(index, 1);
        this.selectedCompareData.splice(index, 1);
      }
      this.checkCompareButtonState();
    }
  }
  clearAllSelections() {
    this.selectedCompareRunIDS = []; // Reset custom selection arrays
    this.selectedCompareData = [];
    this.checkCompareButtonState(); //Compare button handling
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  exportToExcel(runId: any): void {
    this.isModalLoader = true;
    this.validator.downloadValidationDetails(runId).subscribe(
      (downloadResponse: Blob) => {
        const contentType = downloadResponse.type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([downloadResponse], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${runId}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.translated.get('successfullMessages.SUCCESS_FILE_DOWNLOAD_MESSAGE').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
        this.isModalLoader = false;
      },
      (error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          this.sessionStorageData.clear();
        }
        if (error.status === 500) {
          this.translated.get('errorMessages.FAILED_DOWNLOAD_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
        }
      }
    );
  }

  applyFilter(filterValue: any, column: any) {
    if (filterValue && filterValue.length >= 0) {
      this.filterValues[column] = filterValue;

    } else {
      this.filterValues[column] = [filterValue];
    }
    // Construct the filter payload based on current filter values
    const filterPayload = {
      is_favorite: this.filterValues.favourite.map((value: any) => value === 'Favourite' ? 'true' : 'false'),
      test_size: this.filterValues.testSize,
      file_type: this.filterValues.fileType,
      file_name: this.filterValues.fileName
    };

    // Emit the filter payload
    this.filterQuery.emit(filterPayload);
  }
  isFilterApplied(columnKey: string): boolean {
    return this.filterValues[columnKey] && this.filterValues[columnKey].length > 0;
  }
  getUniqueValues(column: string): string[] {
    this.populateUniqueValues();
    switch (column) {
      case 'fileType':
        return this.uniqueFileTypes;
      case 'testSize':
        return this.uniqueTestSizes;
      case 'fileName':
        return this.uniqueFileNames;
      case 'favourite':
        return this.uniqueFavourite;
      default:
        return [];
    }
  }

  addFavourits(row: any) {
    const payload = {
      is_favorite: row.favourite ? false : true,
      run_id: row.runid
    }
    this.isLoader = true;
    this.validator.updateValidatorWithFavorites(payload).subscribe((res: any) => {
      this.isLoader = false;
      this.translated.get('successfullMessages.FAVOURITES_ACTION_SUCCESS').subscribe((translatedText: string) => {
        this.toaster.success(translatedText);
      });
      // this.toaster.success(res.status);
      this.updatefavorite.emit(res.status);
    }, (error: any) => {
      this.isLoader = false;
    })
  }

  clearFilter(column: string) {
    this.filterModel[column] = [];
    this.filterValues[column] = [];
    this.applyFilter([], column);
    this.validator.validatorTableFilter = null;
    this.isDropdownOpen[column] = false;
  }

  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorageData.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
}
