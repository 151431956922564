import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ValidatorServiceService } from '../services/validator-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DateFormatService } from '../pipe/date-time-localize';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../services/chat.service';
import { LocalStorageService } from '../services/localStorageService';
import { StateService } from '../services/shared-object.service';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  isLoader = false;
  botLoderType = 'boxLoader';
  isDesktop = false;
  dataSourceValidationTest: any[] = [];
  dataSourceCoversationResult: any[] = [];
  totalTablePage: number = 0;
  validationColumns: any[] = [
    { key: 'checkbox', header: '' },
    { key: 'username', header: 'headers.USER_NAME' },
    { key: 'runid', header: 'headers.RUN_ID' },
    { key: 'fileType', header: 'headers.TYPE' },
    { key: 'fileName', header: 'headers.FILE_NAME' },
    { key: 'datetime', header: 'headers.DATE_TIME' },
    { key: 'status', header: 'headers.STATUS' },
    { key: 'testSize', header: 'sideNav.TEST_SIZE' },
    { key: 'comment', header: 'chatBot.CMT_TEXT' },
    { key: 'action', header: 'devConfig.ACTION' },
    { key: 'favourite', header: 'cache.TRANS_FAVOURITE' }
  ];

  isModalLoader: boolean = false;
  searchQuery: string = '';
  isValidData: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 15;
  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup | undefined;
  selectedCurrentRecord: any;
  currentSearchQry: any;
  currentTab: string = '';
  isRePhrase: boolean = false;
  rePhraseQuestion: string = '';
  rePhraseGroundTruth: string = '';
  isRephraseOptions: string = '';
  testSizeOptions: string[] = ['1', '2', '3'];
  testSize: string = '';
  validatorOptions: any;
  isExpandCollapse: boolean = true
  constructor(private validator: ValidatorServiceService, private toaster: ToastrService, private router: Router, public dialog: MatDialog, public dateFormatService: DateFormatService, private translated: TranslateService, private chatbotService: ChatbotService, private sessionStorage: LocalStorageService, private stateService: StateService) { }

  ngOnInit(): void {
    this.fetchValidatorOptions();
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.isValidData = false;
    this.dataSourceValidationTest = [];
    if (this.validator.validatorTableFilter) {
      this.searchUser(this.validator.validatorTableFilter, this.pageNumber);
    } else {
      this.searchTableData(pageNumber);
    }
  }

  rowClickOption(event: { currentRecordSelected: any, index: number }) {
    if (event?.currentRecordSelected?.status === 'Deleted Sucessfully') {
      this.selectedCurrentRecord = event?.currentRecordSelected;
      this.translated.get('successfullMessages.DELETED_SUCESSFULLY').subscribe((translatedText: string) => {
        this.toaster.success(translatedText);
      });
      this.searchTableData(this.pageNumber);
    } else if (event?.currentRecordSelected?.status === "completed") {
      this.isModalLoader = true;
      this.selectedCurrentRecord = event?.currentRecordSelected;
      const payloadDetails = { "user_name": event?.currentRecordSelected.username, "run_id": event?.currentRecordSelected.runid }
      this.getRecord(payloadDetails, event.index);
    }
  }
  getRecord(payloadDetails: any, currentIndex: number) {
    this.validator.getValidationDetailInfo(payloadDetails).subscribe((res: any) => {
      this.isModalLoader = false;
      const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
        width: '100%',
        maxWidth: 'none',
        disableClose: true,
        data: {
          isValidEvalData: true,
          evalData: { selectedRecordToView: res, currentDataIndex: currentIndex },
          runId: this.selectedCurrentRecord.runid
        },
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }, (error: any) => {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    })
  }

  searchTableData(pageNumber: any) {
    if (this.searchQuery !== "") {
      this.isModalLoader = true;
      this.currentSearchQry = this.searchQuery;
      const payloadSearch = { "search_text": this.searchQuery.replace(/\s/g, "").toLowerCase() }
      this.searchUser(payloadSearch, pageNumber);
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.info(translatedText);
      });
    }
  }
  searchUser(payloadSearch: any, pageNumber: any) {
    this.totalTablePage = 1;
    this.isModalLoader = true;
    this.validator.searchValidationDetail(payloadSearch, pageNumber, this.pageSize).subscribe((response: any) => {
      this.isModalLoader = false;
      this.isValidData = true;
      this.dataSourceValidationTest = response?.
        data.map((item: any) => ({
          username: item.user_name,
          runid: item.run_id,
          datetime: this.dateFormatService.convertUtcToLocalSlash(item.time_stamp),
          status: item.status,
          fileType: item.file_type,
          fileName: item.file_name,
          testSize: item.test_size,
          favourite: item.is_favorite,
          comment: item.comment
        }));
      this.totalTablePage = response['total_no_of_pages'];
    }, (error: any) => {
      this.isModalLoader = false;
    })
  }
  clearSearch() {
    if (this.searchQuery !== "") {
      this.searchQuery = "";
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.info(translatedText);
      });
    }
  }

  clickCompare(comparesData: any) {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        isValidCompareData: true,
        compareData: { comparesData },
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  backToSearch() {
    this.pageNumber = 1;
    this.isValidData = false;
    this.dataSourceValidationTest = []
    this.dataSourceCoversationResult = []
    this.searchQuery = "";
  }
  showRePhrase() {
    this.isRePhrase = !this.isRePhrase;
  }
  clearRePhrQuestion() {
    this.rePhraseQuestion = "";
  }
  clearRePhrAnswer() {
    this.rePhraseGroundTruth = "";
  }
  isRephraseChange() {

  }
  SaveRephrase() {
    const savePayload = new FormData();
    savePayload.append('question', this.rePhraseQuestion);
    savePayload.append('ground_truth', this.rePhraseGroundTruth);
    savePayload.append('rephrase', this.isRephraseOptions);
    savePayload.append('testsize', this.testSize);
    this.validator.validatorRephrase(savePayload).subscribe((response: any) => {
      this.isRePhrase = false;
    }, (error: any) => {
      console.error('add Response:', error);
    })
  }

  searchfilter(filterValue: any) {
    const searchPayload = {
      search_text: this.searchQuery,
      "is_favorite": filterValue.is_favorite,
      "test_size": filterValue.test_size,
      "file_type": filterValue.file_type,
      "file_name": filterValue.file_name
    }
    this.validator.validatorTableFilter = searchPayload;
    this.searchUser(searchPayload, this.pageNumber);
  }
  favoriteStatusUpdate(message: any) {
    if (message) {
      this.searchTableData(this.pageNumber);
    }
  }
  inputChangeSearch(values: string) {
    const regex = /^[a-zA-Z,]*$/;
    if (!regex.test(values)) {
      this.translated.get('errorMessages.VALIDATOR_USERNAME_SEPRATED_COMMA').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }

  addNewEvaluation() {
    const initialChatpaload = {
      "language_type": this.sessionStorage.get('language'),
      "query": "Hi",
      "bot_type": this.sessionStorage.get('bot_type'),
      "userId": this.sessionStorage.get('username')
    }
    this.isModalLoader = true;
    this.fetchValidatorOptions()
    this.chatbotService.initialChatResponse(initialChatpaload).catch((error: any) => {
      // console.log('error:', error);
    }).then((res: any) => {
      if (res) {
        this.isModalLoader = false;
        const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
          width: 'auto',
          disableClose: true,
          data: {
            isValidValidator: true,
            validator: this.validatorOptions,
          },
        });

        dialogRef.afterClosed().subscribe(result => {
        });
      }
    })
  }

  fetchValidatorOptions(): void {
    this.validator.getValidatorOptions().subscribe(
      (data: any[]) => {
        this.validatorOptions = data;
      },
      (error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      }
    );
  }
}
