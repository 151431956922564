<div fxLayou="row" fxLayoutAlign="end center" fxLayoutGap="20">
  <button mat-button class="mui-button compare-right-space" [disabled]="compareButtonDisabled" (click)="compareData()">
    {{'disclosure.COM' | translate}}
  </button>
</div>
<div>
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-table" matSort>
    <!-- Dynamic Column Definitions -->
    <div *ngFor="let column of columns" [matColumnDef]="column.key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'comment-column': column.key === 'comment'}">
        <div fxLayout="row" fxLayoutGap="20" fxLayoutAlign="space-between center">
          <span>{{ column.header | translate}}</span>
          <div *ngIf="['fileName', 'testSize', 'fileType', 'favourite'].includes(column.key)">
            <mat-icon [id]="'icon-' + column.key" mat-icon-button (click)="toggleDropdown(column.key, $event)"
              class="cursor">
              arrow_drop_down
            </mat-icon>
            <div *ngIf="isDropdownOpen[column.key]" class="popover" [ngStyle]="popoverStyles"
              (click)="$event.stopPropagation()">
              <div *ngIf="['fileName', 'testSize', 'fileType', 'favourite'].includes(column.key)" fxLayout="row"
                fxLayoutGap="10px">
                <mat-form-field>
                  <mat-label>Filter</mat-label>
                  <mat-select [(ngModel)]="filterModel[column.key]"
                    (selectionChange)="applyFilter($event.value, column.key)" multiple>
                    <mat-option *ngFor="let option of getUniqueValues(column.key)" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button *ngIf="isFilterApplied(column.key)" mat-icon-button
                  (click)="clearFilter(column.key);$event.stopPropagation()">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>

            </div>
          </div>
        </div>
      </th>
      <td mat-cell [ngClass]="{'comment-column': column.key === 'comment'}" *matCellDef="let element"
        [class.cursor]="column.key" (click)="onRunIDClick(element)">
        <div *ngIf="column.key === 'checkbox'">
          <mat-checkbox [disabled]="element?.status ==='Processing'" (click)="$event.stopPropagation()"
            (change)="onCheckboxChange($event, element)" [checked]="isSelected(element)"
            [aria-label]="checkboxLabel(element)">
          </mat-checkbox>
        </div>
        <div *ngIf="column.key !== 'action' && column.key !== 'favourite'">
          <span *ngIf="column.key === 'comment'" matTooltip="{{ element[column.key] }}">
            {{ element[column.key] }}
          </span>
          <span *ngIf="column.key !== 'comment'">
            {{ element[column.key] }}
          </span>
        </div>
        <div *ngIf="column.key === 'favourite'">
          <button mat-icon-button [ngClass]="{'yellow-icon': element?.favourite}"
            (click)="addFavourits(element);$event.stopPropagation()"><mat-icon>star</mat-icon></button>
        </div>
        <div *ngIf="column.key === 'action'">
          <div fxLayout="row" fxLayoutGap="20">
            <div>
              <mat-icon class="custom-icon" [ngClass]="{'disabled-icon': element?.status === 'Processing'}"
                (click)="exportToExcel(element.runid);$event.stopPropagation();"
                matTooltip="{{'sideNav.DOWNLOAD_TEXT_RUNID_DATA' | translate}}" aria-hidden="false"
                aria-label="Download" fontIcon="file_download"></mat-icon>
            </div>
            <div>
              <img (click)="onDelete(element);$event.stopPropagation();" [ngClass]="{
                'custom-icon': true,
                'disabled-img': element?.status === 'Processing',
                'disabled-interaction': element?.status === 'Processing'
               }" [style.pointer-events]="element?.status === 'Processing' ? 'none' : 'auto'"
                src="assets/trash 1-black.svg" alt="Remove File" />
            </div>
          </div>
        </div>
      </td>
    </div>

    <div matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <span aria-label="expand row"
          (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation(); onRunIDClick(element)">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </span>
      </td>
    </div>

    <div matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
        <div class="parent-element-container" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="inner-table-container width-100">
            <div *ngIf="!isLoader; else loading" class="width-100">
              <mat-table mat-table *ngIf="dataSourceValidation.length > 0" [dataSource]="dataSourceValidation"
                class="width-100 mat-elevation-z8">

                <div matColumnDef="question">
      <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_QUESTION' |
        translate }}</th>
      <td mat-cell *matCellDef="let element">{{
        element.question }}</td>
    </div>

    <div matColumnDef="context_recall">
      <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_CON_RECALL' |
        translate }}</th>
      <td mat-cell *matCellDef="let element" class="text-eclipse">{{ element.context_recall|
        number:'1.3-3'}}
      </td>
    </div>

    <div matColumnDef="context_precision">
      <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_CON_PREC' |
        translate }}</th>
      <td mat-cell *matCellDef="let element" class="text-eclipse">{{ element.context_precision |
        number:'1.3-3' }}
      </td>
    </div>

    <div matColumnDef="faithfulness">
      <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_FAITHFUL' |
        translate }}</th>
      <td mat-cell *matCellDef="let element" class=" text-eclipse">{{ element.faithfulness |
        number:'1.3-3' }}</td>
    </div>

    <div matColumnDef="answer_relevancy">
      <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_ANS_REL' | translate
        }}</th>
      <td mat-cell *matCellDef="let element" class=" text-eclipse">{{ element.answer_relevancy |
        number:'1.3-3' }}
      </td>
    </div>

    <div matColumnDef="answer_correctness">
      <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_ANS_CRT' | translate
        }}</th>
      <td mat-cell *matCellDef="let element" class=" text-eclipse">{{ element.answer_similarity
        | number:'1.3-3' }}</td>
    </div>

    <div matColumnDef="eye_icon">
      <th mat-header-cell *matHeaderCellDef>{{'chatBot.VIEW' | translate}}</th>
      <td mat-cell *matCellDef="let element; let index = index">
        <img alt='Validation View Details' class="cursor" (click)="onEyeIconClick(index)" src='assets/eye 1.png' />
      </td>
    </div>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </mat-table>
    <div *ngIf="dataSourceValidation.length === 0" fxLayoutAlign="center center">
      {{'contentMgmt.NO_RECORD_FOUND' | translate}}
    </div>
</div>
<ng-template #loading>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-loader [botLoderType]="botLoderType"></app-loader>
  </div>
</ng-template>
</div>
</div>
</td>
</div>

<!-- Header Row -->
<tr mat-header-row *matHeaderRowDef="displayedColumnsKeys"></tr>
<!-- Data Rows -->
<tr mat-row *matRowDef="let row; columns: displayedColumnsKeys;" class="element-row"
  [class.example-expanded-row]="expandedElement === row"
  (click)="expandedElement = expandedElement === row ? null : row"></tr>
<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detailed-row"></tr>
</table>
</div>
<!-- No records found message -->
<div *ngIf="isDataSourceEmpty" class="no-records-found">
  {{'contentMgmt.NO_RECORD_FOUND' | translate}}
</div>
<!--Pagination-->
<div class="pagination" *ngIf="!isDataSourceEmpty && !filterHasValues">
  <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
    <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
  </button>
  <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
    <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
  </button>
  <span>{{ pageNumber }} / {{ totalTablePage }}</span>
  <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage"
    [disabled]="pageNumber === totalTablePage">
    <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
  </button>
  <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage"
    [disabled]="pageNumber === totalTablePage">
    <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
  </button>
</div>
<div *ngIf="isModalLoader" class="modal-backdrop">
  <app-loader [botLoderType]="botLoderType"></app-loader>
</div>