<style>
  .spacer {
    flex: 1;
  }

  .header {
    padding: 0 20px;
  }

  .pad-lft35 {
    padding-left: 35px;
  }

  .content {
    display: flex;
    overflow-y: auto;
  }

  svg.material-icons {
    height: 24px;
    width: auto;
  }

  svg.material-icons:not(:last-child) {
    margin-right: 8px;
  }


  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }


  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 5px;
  }

  .font-size20 {
    font-size: 20px;
  }

  .bg-white {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .content-center {
    display: flex;
    align-items: center !important;
  }
</style>
<mat-sidenav-container class="side-nav-container">
  <mat-sidenav #sidenav  [disableClose]="true"
    *ngIf="!isLoginPage() && !isForgotPasswordPage() && !isPasswordResetPage() && !isThankYouPage() && !isPasswordResetPage()"
    [mode]="mode" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">
    <app-chatsidenav (actionClickedFromSideNav)="handleActionClick($event)"></app-chatsidenav>
  </mat-sidenav>

  <mat-sidenav-content #sidenavContent class="bg-image">
    <!-- Header -->
   
    <!-- Main Content -->
    <ngx-guided-tour backText="Back" skipText="Skip"></ngx-guided-tour>
    <div class="modal-backdrop" *ngIf="isModalLoader">
      <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
    <!-- Footer -->
    <footer fxLayoutAlign="center center" *ngIf="isLoginPage() || isPasswordResetPage() || isForgotPasswordPage()">
      <div class='footer-row'>
        {{'login.POWERD_BY' | translate}}
        <img alt='Kumaran Systems Private Limited' src='assets/kumaran icon.png' />
        <a href="https://kumaran.com/" target="_blank" rel="noopener noreferrer" class='forgot-password-link'>Kumaran
          Systems</a>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
<router-outlet></router-outlet>