import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment.dev'
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss']
})
export class PasswordResetPageComponent implements OnInit {
  resetForm!: FormGroup;
  currentPath: string = '';
  submitted: boolean = false;
  environment = '';
  autheService = environment.apiBaseUrl['authService'];
  hideConfirmPassword: boolean = true;
  loginURL: string = '';
  botType: any = ''
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private translated: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.botType = sessionStorage.getItem('bot_type')
    this.resetForm = this.fb.group({
      userName: new FormControl('', [Validators.required, this.emailDomainValidator()]),
      newPassword: new FormControl('', [Validators.required])
    });
    if (environment.production === false) {
      this.environment = 'dev'
    }
    this.route.queryParams.subscribe(params => {
      // Initialize an empty array to store key-value pairs
      const keyValuePairs: string[] = [];
      // Iterate side the keys to get all parameter names and values
      Object.keys(params).forEach(key => {
        const value = params[key];
        keyValuePairs.push(`${key}=${value}`);
      });
      // Concatenate the key-value pairs into a single string
      this.currentPath = keyValuePairs.join('&');
    });
    this.loginURL = environment.apiBaseUrl['loginUrl']
  }

  handleSubmit(): void {
    const body = new FormData();
    const userName = this.resetForm.value.userName as string;
    const newPassword = this.resetForm.value.newPassword as string;
    if (newPassword) {
      body.append('password', newPassword);
      this.http.post(`${this.autheService}/resetpwd?TOKEN_TEXT=${this.currentPath}&USERNAME=${this.resetForm.value.userName}&bot_type=cibc`, body)
        .subscribe(
          (response: any) => {
            this.submitted = true;
          },
          (error: any) => {
            if (error.status === 401) {
              this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
              this.router.navigate(['/login']);
              sessionStorage.clear();
            }
            if (error.status === 409) {
              this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
              this.router.navigate(['/login']);
              sessionStorage.clear();
            }
            if (error?.status === 400) {
              if (error?.error?.msg === 'Current password cannot be same as previous.') {
                const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
                  width: 'auto',
                  disableClose: true,
                  data: {
                    passwordNotSamePrevious: true,
                  },
                });

                dialogRef.afterClosed().subscribe(result => {
                });
              } else if (error?.error?.msg === 'New password does not meet the required criteria') {
                const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
                  width: 'auto',
                  disableClose: true,
                  data: {
                    passwordNotValid: true,
                  },
                });

                dialogRef.afterClosed().subscribe(result => {
                });
              } else if (error?.error?.msg === 'Username mismatch') {
                this.translated.get('errorMessages.PASSWORD_USR_NAME_MISMATCH').subscribe((translatedText: string) => {
                  this.toaster.error(translatedText);
                });
              }
            }
          }
        );
    } else {
      this.toaster.error('Please enter Password!')
    }
  }

  emailDomainValidator(): ValidatorFn {
    const emailPattern = /^(?=.{5,60}$)(?!.*\.\.)(?!.*@.*@)[a-zA-Z0-9._%-]+@(cibc\.com|cibc\.co|kumaran\.com|kumaran\.world)$/i;
    return (control: AbstractControl): ValidationErrors | null => {
      return emailPattern.test(control.value) ? null : { invalidEmail: true };
    };
  }



}
