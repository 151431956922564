<div class="page-containter"
    [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div *ngIf="!isLoader" class="table-content-fit-page">
        <div class="reports-type-container">
            <mat-label class="reports-type-label">{{ 'reports.REPORT_TYPE' | translate }}</mat-label>
            <div>
                <mat-checkbox [(ngModel)]="performanceChecked"
                    (change)="onCheckboxChange('performance', $event.checked)">{{ 'reports.RESPONSE_TIME_CHECK' |
                    translate
                    }}</mat-checkbox>
                <mat-checkbox [(ngModel)]="feedbackChecked" (change)="onCheckboxChange('feedback', $event.checked)">{{
                    'reports.FEEDBACK' | translate }}</mat-checkbox>
                <mat-checkbox [(ngModel)]="usageChecked" (change)="onCheckboxChange('usage', $event.checked)">{{
                    'rating.SYNONYMS_USAGE' | translate }}</mat-checkbox>
                <mat-checkbox [(ngModel)]="tokenUtilizedChecked"
                    (change)="onCheckboxChange('tokenutilized', $event.checked)">{{ 'reports.TOKEN_UTILIZED' | translate
                    }}</mat-checkbox>
            </div>
        </div>
        <div *ngIf="performanceChecked || feedbackChecked || usageChecked || tokenUtilizedChecked" class="input-grid">
            <!-- Existing Date Filter Fields -->
            <div fxLayout="row" fxLayoutGap="20">
                <mat-form-field appearance="fill" class="width-full">
                    <mat-label>{{ 'reports.OPENAI_MODEL' | translate }}</mat-label>
                    <mat-chip-grid #modelChipGrid aria-label="Model selection">
                        <mat-chip-row *ngFor="let model of selectedAIModels"
                            (removed)="remove(model, selectedAIModels)">
                            {{model}}
                            <button matChipRemove [attr.aria-label]="'remove ' + model">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="{{ 'reports.NEW_MODEL' | translate }}" #aiModelInput [formControl]="aiModelCtrl"
                        [matChipInputFor]="modelChipGrid" [matAutocomplete]="autoModel"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event, selectedAIModels, aiModelCtrl)" />
                    <mat-autocomplete #autoModel="matAutocomplete"
                        (optionSelected)="selected($event, selectedAIModels, aiModelCtrl)">
                        <mat-option *ngFor="let model of filteredModels | async" [value]="model">
                            {{model}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>


                <mat-form-field appearance="fill" class="width-full">
                    <mat-label>{{ 'reports.LANGUAGE' | translate }}</mat-label>
                    <mat-chip-grid #langChipGrid aria-label="Language selection">
                        <mat-chip-row *ngFor="let lang of selectedLang" (removed)="remove(lang, selectedLang)">
                            {{lang}}
                            <button matChipRemove [attr.aria-label]="'remove ' + lang">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="{{ 'reports.NEW_LANGUAGE' | translate }}" #langInput [formControl]="langCtrl"
                        [matChipInputFor]="langChipGrid" [matAutocomplete]="autoLang"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event, selectedLang, langCtrl)" />
                    <mat-autocomplete #autoLang="matAutocomplete"
                        (optionSelected)="selected($event, selectedLang, langCtrl)">
                        <mat-option *ngFor="let lang of filteredLang | async" [value]="lang">
                            {{lang}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20">
                <mat-form-field appearance="fill" class="width-full">
                    <mat-label>{{ 'reports.DATE' | translate }}</mat-label>
                    <mat-select [(value)]="selectedDateFilter" (selectionChange)="onDateFilterChange($event.value)">
                        <mat-option value="year">{{ 'reports.YEAR' | translate }}</mat-option>
                        <mat-option value="month">{{ 'reports.MONTH' | translate }}</mat-option>
                        <mat-option value="week">{{ 'reports.SELECT_WEEK' | translate }}</mat-option>
                        <mat-option value="date">{{ 'reports.DATE_RANGE' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="selectedDateFilter === 'year'" class="width-full">
                    <mat-label>{{ 'reports.SELECT_YEAR' | translate }}</mat-label>
                    <mat-select [(value)]="selectedYear" multiple>
                        <mat-option *ngFor="let year of yearOptions" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="selectedDateFilter === 'month'" class="width-full">
                    <mat-label>{{ 'reports.SELECT_YEAR_AND_MONTH' | translate }}</mat-label>
                    <mat-select [(value)]="selectedYearMonth" multiple>
                        <mat-option *ngFor="let year of monthYearOptions" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="selectedDateFilter === 'week'" class="width-full">
                    <mat-label>{{ 'reports.SELECT_WEEK' | translate }}</mat-label>
                    <mat-select [(value)]="selectedWeek" multiple>
                        <mat-option *ngFor="let week of weekOptions" [value]="week">{{ week }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="selectedDateFilter === 'date'" class="width-full">
                    <mat-label>{{ 'sideNav.START_DATE' | translate }}</mat-label>
                    <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate" [max]="today">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="selectedDateFilter === 'date'" class="width-full">
                    <mat-label>{{ 'sideNav.END_DATE' | translate }}</mat-label>
                    <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" [min]="startDate" [max]="today">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20">
                <mat-form-field class="width-full" appearance="fill">
                    <mat-label>{{ 'reports.USER_ID' | translate }}</mat-label>
                    <mat-chip-grid #userChipGrid aria-label="User selection">
                        <mat-chip-row *ngFor="let user of users" (removed)="remove(user,users)">
                            {{user}}
                            <button matChipRemove [attr.aria-label]="'remove ' + user">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>

                    <div class="input-with-icon">
                        <input placeholder="{{ 'reports.NEW_USER' | translate }}" #userInput [formControl]="userCtrl"
                            [matChipInputFor]="userChipGrid" [matAutocomplete]="auto"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event, users, userCtrl)" (focus)="onFocus()"
                            (blur)="onBlur()" />

                        <button mat-icon-button (click)="searchUser(userCtrl.value)">
                            <mat-icon>search</mat-icon>
                        </button>

                        <button mat-icon-button (click)="clearInput()">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, users, userCtrl)">
                        <mat-option *ngIf="loading" class="loading-option">
                            {{ 'reports.LOADING' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                            {{user}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill" class="width-full">
                    <mat-label>{{ 'feedback.RESPONSE_TYPE' | translate }}</mat-label>
                    <mat-chip-grid #respChipGrid aria-label="resp selection">
                        <mat-chip-row *ngFor="let resp of selectedResponseType"
                            (removed)="remove(resp, selectedResponseType)">
                            {{resp}}
                            <button matChipRemove [attr.aria-label]="'remove ' + resp">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="{{ 'reports.NEW_RESP' | translate }}" #respInput [formControl]="respTypeCtrl"
                        [matChipInputFor]="respChipGrid" [matAutocomplete]="autoResp"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event, selectedResponseType, respTypeCtrl)" />
                    <mat-autocomplete #autoResp="matAutocomplete"
                        (optionSelected)="selected($event, selectedResponseType, respTypeCtrl)">
                        <mat-option *ngFor="let resp of filteredRespType | async" [value]="resp">
                            {{resp}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20">
                <mat-form-field appearance="fill" class="width-full" *ngIf="!feedbackChecked">
                    <mat-label>{{ 'chatBot.RATING_TEXT' | translate }}</mat-label>
                    <mat-chip-grid #ratChipGrid aria-label="rating selection">
                        <mat-chip-row *ngFor="let rat of selectedRating" (removed)="remove(rat, selectedRating)">
                            {{rat}}
                            <button matChipRemove [attr.aria-label]="'remove ' + rat">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="{{ 'reports.NEW_RATING' | translate }}" #ratInput [formControl]="ratingCtrl"
                        [matChipInputFor]="ratChipGrid" [matAutocomplete]="autoRat"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event, selectedRating, ratingCtrl)" />
                    <mat-autocomplete #autoRat="matAutocomplete"
                        (optionSelected)="selected($event, selectedRating, ratingCtrl)">
                        <mat-option *ngFor="let rat of filteredRating | async" [value]="rat">
                            {{rat}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill" class="width-full" *ngIf="!performanceChecked">
                    <mat-label>{{ 'reports.RESPONSE_TIME' | translate }}</mat-label>
                    <mat-chip-grid #timeChipGrid aria-label="resp time selection">
                        <mat-chip-row *ngFor="let time of selectedResponseTime"
                            (removed)="remove(time, selectedResponseTime)">
                            {{time}}
                            <button matChipRemove [attr.aria-label]="'remove ' + time">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="{{ 'reports.NEW_TIME' | translate }}" #timeInput [formControl]="respTimeCtrl"
                        [matChipInputFor]="timeChipGrid" [matAutocomplete]="autoTime"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event, selectedResponseTime, respTimeCtrl)" />
                    <mat-autocomplete #autoTime="matAutocomplete"
                        (optionSelected)="selected($event, selectedResponseTime, respTimeCtrl)">
                        <mat-option *ngFor="let time of filteredRespTime | async" [value]="time">
                            {{time}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill" class="width-full">
                    <mat-label>{{ 'reports.GROUP_BY' | translate }}</mat-label>
                    <mat-select [(value)]="selectedGroupBy">
                        <mat-option value="model">{{ 'reports.OPENAI_MODEL' | translate }}</mat-option>
                        <mat-option value="language">{{ 'reports.LANGUAGE' | translate }}</mat-option>
                        <mat-option value="year">{{ 'reports.SELECT_YEAR' | translate }}</mat-option>
                        <mat-option value="month">{{ 'reports.SELECT_YEAR_AND_MONTH' | translate }}</mat-option>
                        <mat-option value="week">{{ 'reports.SELECT_WEEK' | translate }}</mat-option>
                        <mat-option value="date">{{ 'reports.DATE_RANGE' | translate }}</mat-option>
                        <mat-option value="usernames">{{ 'reports.USER_ID' | translate }}</mat-option>
                        <mat-option value="response_types">{{ 'feedback.RESPONSE_TYPE' | translate }}</mat-option>
                        <mat-option *ngIf="!feedbackChecked" value="ratings">{{ 'chatBot.RATING_TEXT' | translate
                            }}</mat-option>
                        <mat-option *ngIf="!performanceChecked" value="response_time">{{ 'reports.RESPONSE_TIME' |
                            translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- Buttons -->
        <div *ngIf="performanceChecked || feedbackChecked || usageChecked || tokenUtilizedChecked" fxLayout="row"
            fxLayoutAlign="end center" fxLayoutGap="20">
            <button mat-raised-button class="mui-button-clear" (click)="clearFilters()">{{ 'login.CLEAR' | translate
                }}</button>
            <button mat-raised-button class="mui-button" (click)="generateReport()">{{ 'reports.REPORT' | translate
                }}</button>
        </div>

    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <app-loader [ngClass]="{'loader': isLoader, 'modal-backdrop': isModalLoader}" *ngIf="isLoader || isModalLoader"
            [botLoderType]="botLoderType"></app-loader>
    </div>
</div>
