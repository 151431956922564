<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div fxLayout="row" fxLayoutGap="30" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch">
        <div class="width-half" fxLayoutAlign="end center">
            <button mat-button class="mui-button" (click)="addNewEvaluation()">{{'disclosure.ADD_EVALUATION'|
                translate}}</button>
        </div>
        <div class="width-half" fxLayoutAlign="end center">
            <mat-form-field class="width-full" appearance="fill">
                <mat-label>{{'validator.FILTER_SEARCH_TEXT' | translate}}</mat-label>
                <input matInput #searchInput [(ngModel)]="searchQuery" (ngModelChange)="inputChangeSearch($event)"
                    (keydown.enter)="searchTableData(pageNumber)" required>
                <button mat-icon-button matSuffix (click)="searchTableData(pageNumber)">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button matSuffix (click)="clearSearch()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <span fxLayoutAlign="end center" class='font-size12'>{{'validator.FILTER_SEARCH_HELP_TEXT' |
        translate}}</span>
    <!-- Existing Content -->

    <div class="table-content-fit-page">
        <app-mat-table *ngIf="dataSourceValidationTest.length > 0 && !isRePhrase && !isLoader; else noRecord"
            [columns]="validationColumns" [data]="dataSourceValidationTest" [pageNumber]="pageNumber"
            [totalTablePage]="totalTablePage" (onPageChangeEvent)="onPageChange($event)"
            (usernameClick)="rowClickOption($event)" (compareClick)="clickCompare($event)"
            (filterQuery)="searchfilter($event)" (updatefavorite)="favoriteStatusUpdate($event)">
        </app-mat-table>
        <ng-template #noRecord>
            <div *ngIf="dataSourceValidationTest.length === 0" fxLayoutAlign="center center">
                {{ 'contentMgmt.NO_RECORD_FOUND' | translate }}
            </div>
        </ng-template>

    </div>

</div>
<!-- Modal Loader -->
<div *ngIf="isModalLoader" class="modal-backdrop">
    <app-loader [botLoderType]="botLoderType"></app-loader>
</div>