import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceSpecifiedLabelsService {
  translations: { [key: string]: { [key: string]: string } } = {
    en: {
      RATING_TAG_TEXT: 'Does that answer your question?',
      STEPWISE_TEXT: 'Stepwise',  // Corrected from STEPSWISE_TEXT to STEPWISE_TEXT
      TOCACHE_TEXT: 'To cache',
      DISCLOSURE: 'Disclosure',
      LANGUAGE_CONFIRMATION: 'To observe the Language Change take effect, please log out.',
      SENSITIVE_CONFIRMATION_TITLE: 'Confirm Action',
      SENSITIVE_CONFIRMATION: 'Are you sure you want to approve or reject this feedback?',
      ADDITIONAL_MESSAGE_LABEL: 'Please note that this chatbot is an AI-based assistant designed to provide information and answer questions related to CIBC Investor Bank products and services. It is not intended to offer professional financial or investment advice.',
      CHITCHAT_CONFIRMATION: "Are you certain you wish to mark the following question as chit chat and approve / reject?",
      SENSITIVE_RES_TEXT: "I'm not able to answer that question. I can't provide you with professional financial, investing or tax advice.",
      NO_CONTENT_RES_TEXT: "Kindly rephrase the question and check",
      OUT_OF_SCOPE_RES_TEXT: "I don't have any relevant content in my knowledge base to answer this question. Try asking your question differently, or check out some similar questions.",
      LANGUAGE_MISMATCH_FR: "Veuillez poser la question en français uniquement",
      LANGUAGE_MISMATCH_EN: "Kindly ask the question in English language only",
      PII_RES_TEXT: "The question cannot be processed because it contains PII(Personally identifiable information).",
      PROFILE_PICTURE_UPLOAD: "Upload Profile Picture",
      CHATBOT_UNDEFIND_TEXT: "We regret to inform you that we were unable to provide an answer to your query at this time. Please consider rephrasing your query or contacting support for further assistance. Please start a new conversation",
      START_NEW_CONVERSATION_MESSAGE: "Your conversation session has been expired. Please start a new one",
      DELETE_CONFIRM_LABEL: "Are you sure to want to delete following record?",
      DELETE_CONFIRM_FILE_NAME: "File Name",
      DELETE_CONFIRM_FOLLOWING: "with following",
      DELETE_CONFIRM_RUNID: "RUNID",
      DELETE_CONFIRM_AND: "&",
      DELETE_CONFIRM_FILE_TYPE: "File Type",
      ADD_CONTENT_CONFIRM_LABEL1: " Are you sure to add ",
      ADD_CONTENT_CONFIRM_LABEL2: "content for Chatbot to consider for generating response for the questions it receives? This might have significant impact on the response generated by Chatbot for the related questions. The default boosting score will be 4 (if not given), which determines the ranking of the content to consider while generating response for the question. Note that website content take longer time to parse and index and webserver might block our IP if the traffic is not expected from our IP continously",
      DELETE_CONFIRM_APP_NAME: "Application Name",
      DELETE_CONFIRM_KEY: "Key",
      DELETE_CONFIRM_VALUE: "Value",
      DELETE_TARGET_FDBK: "Target Feedback",
      DELETE_TARGET_FDCOMMENT: "Feedback Comment",
      ANS_FROM_CACHE: "Answer generated from cache",
      TOUR_WELCOME: "Expand/Collapse Side Menu Toggle",
      TOUR_NEW_CONVERSATION: "New Conversation",
      TOUR_LANGUAGE_INPUT: "Language Input for Chatbot",
      TOUR_ASK_QUESTION: "Chatbot Input for Ask Question",
      TOUR_SEND_OPTION: "Send",
      TOUR_SIMILAR_QUESTION: "Similar Question",
      TOUR_WELCOME_CONTENT: "Clicking the icon toggles between these modes, allowing users to personalize their navigation experience based on their current needs.",
      TOUR_NEW_CONVERSATION_CONTENT: "Clicking this icon will end the current conversation and start a fresh chat session.",
      TOUR_LANGUAGE_INPUT_CONTENT: "This language toggle allows you to switch the chatbot's response language.. ",
      TOUR_ASK_QUESTION_CONTENT: "Type your question in the chatbot's text box to start the conversation. ",
      TOUR_SEND_OPTION_CONTENT: "A send icon to submit your message in the chat.",
      TOUR_SIMILAR_QUESTION_CONTENT: "You can view a list of similar questions found in the database.",
      TOUR_CHAT_BOT_TITLE: "Chatbot",
      TOUR_CHAT_BOT_DESC: "This is the default landing page where users can ask questions via chat. Clicking this option will navigate back to the chatbot conversation page from any other menu location.",
      TOUR_CHAT_TRANSCRIPT_TITLE: "Chat Transcript (Chat History)",
      TOUR_CHAT_TRANSCRIPT_DESC: "This menu navigates to the Chat Transcript page, where users can view their previous conversation history.",
      TOUR_CONFIG_TITLE: "Development Configuration",
      TOUR_CONFIG__DESC: "Clicking this menu will take you to the Development Configuration page.",
      TOUR_VALIDATOR_TITLE: "Validator",
      TOUR_VALIDATOR__DESC: "This menu navigates to the Validator page, where users can evaluate and validate the performance accuracy of chatbot responses. ",
      TOUR_FEEDBACK_MGMT_TITLE: "Feedback Management",
      TOUR_FEEDBACK_MGMT__DESC: `
      <p>Access the following pages by clicking the Feedback Management menu:</p>
      <ol>
      <li>Content </li>
      <li>Feedback Options</li> 
      <li>Cache Q&A </li>
      <li> User Comments</li>
      <li> Reports</li>
      </ol>`,
      TOUR_BOT_CONFIG_TITLE: "Bot Configuration",
      TOUR_BOT_CONFIG__DESC: "This menu navigates to the Bot Configuration page, where users can configure the chat model and other related settings. ",
      TOUR_REPORTS_TITLE: "Reports",
      TOUR_REPORTS__DESC: "Clicking this menu will take you to the bot Reports page.",
      TOUR_CHAT_LOADER_TITLE: "Loader",
      TOUR_CHAT_LOADER_DESC: "Loader will display until response comes",
      TOUR_CHAT_RESPONSE_TITLE: "Response Content",
      TOUR_CHAT_RESPONSE_DESC: "The response will appear according to the question posed.",
      IEIIS_TITLE: "IE or IIS",
      IEIIS_Description: "This toggle helps tailor the chatbot's answers based on the selected IE or IIS option.",
      USER_MGMT_TITLE: "User Management",
      USER_MGMT_DESE: "This menu navigates to the User Management page, where role assignments can be managed, and user profile changes can be made.",
    },
    fr: {
      RATING_TAG_TEXT: 'Est-ce que cela répond à votre question ?',
      STEPWISE_TEXT: 'Étape par étape',
      TOCACHE_TEXT: 'Vers le cache',
      DISCLOSURE: 'Divulgation',
      LANGUAGE_CONFIRMATION: 'Pour observer que le changement de langue prend effet, veuillez vous déconnecter.',
      SENSITIVE_CONFIRMATION_TITLE: "Confirmer l'action",
      SENSITIVE_CONFIRMATION: 'Êtes-vous sûr de vouloir approuver ou rejeter ce commentaire ?',
      ADDITIONAL_MESSAGE_LABEL: "Veuillez noter que ce chatbot est un assistant basé sur l'IA conçu pour fournir des informations et répondre aux questions liées aux produits et services de la Banque CIBC Investisseurs. Il n’est pas destiné à offrir des conseils financiers ou d’investissement professionnels.",
      CHITCHAT_CONFIRMATION: "Êtes-vous certain de vouloir marquer la question suivante comme bavardage et approuver/rejeter ?",
      SENSITIVE_RES_TEXT: "Je ne suis pas en mesure de répondre à cette question. Je ne peux pas vous fournir de conseils professionnels en matière de finances, d’investissement ou fiscaux.",
      NO_CONTENT_RES_TEXT: "Veuillez reformuler la question et vérifier",
      OUT_OF_SCOPE_RES_TEXT: "Je n'ai aucun contenu pertinent dans ma base de connaissances pour répondre à cette question. Essayez de poser votre question différemment ou consultez des questions similaires.",
      LANGUAGE_MISMATCH_FR: "Veuillez poser la question en français uniquement",
      LANGUAGE_MISMATCH_EN: "Kindly ask the question in English language only",
      PII_RES_TEXT: "La question ne peut pas être traitée car elle contient des PII (informations personnellement identifiables).",
      PROFILE_PICTURE_UPLOAD: "Télécharger une photo de profil",
      CHATBOT_UNDEFIND_TEXT: "Nous avons le regret de vous informer que nous ne sommes pas en mesure de répondre à votre question pour le moment. Veuillez envisager de reformuler votre requête ou de contacter l'assistance pour obtenir de l'aide. Veuillez démarrer une nouvelle conversation",
      START_NEW_CONVERSATION_MESSAGE: "Votre session de conversation a expiré. S'il vous plaît, commencez-en un nouveau",
      DELETE_CONFIRM_LABEL: "Etes-vous sûr de vouloir supprimer l'enregistrement suivant ?",
      DELETE_CONFIRM_FILE_NAME: "Nom de fichier",
      DELETE_CONFIRM_FOLLOWING: "avec la suite",
      DELETE_CONFIRM_RUNID: "RUNID",
      DELETE_CONFIRM_AND: "&",
      DELETE_CONFIRM_FILE_TYPE: "Type de fichier",
      ADD_CONTENT_CONFIRM_LABEL1: "Etes-vous sûr d'ajouter ",
      ADD_CONTENT_CONFIRM_LABEL2: "Quel contenu le Chatbot doit-il prendre en compte pour générer des réponses aux questions qu'il reçoit ? Cela pourrait avoir un impact significatif sur la réponse générée par Chatbot pour les questions associées. Le score de boosting par défaut sera de 4 (s'il n'est pas indiqué), ce qui détermine le classement du contenu à prendre en compte lors de la génération de réponses à la question. Notez que le contenu du site Web prend plus de temps à être analysé et indexé et que le serveur Web peut bloquer notre IP si le trafic n'est pas attendu de notre IP en continu.",
      DELETE_CONFIRM_APP_NAME: "Nom de l'application",
      DELETE_CONFIRM_KEY: "Clé",
      DELETE_CONFIRM_VALUE: "Valeur",
      DELETE_TARGET_FDBK: "Cibler les commentaires",
      DELETE_TARGET_FDCOMMENT: "Commentaire",
      ANS_FROM_CACHE: "Réponse générée à partir du cache",
      TOUR_WELCOME: "Développer/Réduire le menu latéral",
      TOUR_NEW_CONVERSATION: "À propos de la nouvelle conversation",
      TOUR_LANGUAGE_INPUT: "Entrée de langue pour le chatbot",
      TOUR_ASK_QUESTION: "Saisie de question pour le chatbot",
      TOUR_SEND_OPTION: "Envoyer",
      TOUR_SIMILAR_QUESTION: "Question similaire",
      TOUR_WELCOME_CONTENT: "En cliquant sur l'icône, vous basculez entre ces modes, permettant aux utilisateurs de personnaliser leur expérience de navigation en fonction de leurs besoins actuels.",
      TOUR_NEW_CONVERSATION_CONTENT: "Cliquer sur cette icône mettra fin à la conversation en cours et démarrera une nouvelle session de discussion.",
      TOUR_LANGUAGE_INPUT_CONTENT: "Ce changement de langue vous permet de changer la langue de réponse du chatbot.",
      TOUR_ASK_QUESTION_CONTENT: "Tapez votre question dans la zone de texte du chatbot pour démarrer la conversation.",
      TOUR_SEND_OPTION_CONTENT: "Une icône d'envoi pour soumettre votre message dans le chat.",
      TOUR_SIMILAR_QUESTION_CONTENT: "Vous pouvez voir une liste de questions similaires trouvées dans la base de données.",
      TOUR_CHAT_BOT_TITLE: "Menu des chatbots",
      TOUR_CHAT_BOT_DESC: "Il s'agit de la page de destination par défaut sur laquelle les utilisateurs peuvent poser des questions via le chat. En cliquant sur cette option, vous reviendrez à la page de conversation du chatbot à partir de n’importe quel autre emplacement de menu.",
      TOUR_CHAT_TRANSCRIPT_TITLE: "Transcription du chat (historique du chat)",
      TOUR_CHAT_TRANSCRIPT_DESC: "Ce menu accède à la page Transcription du chat, où les utilisateurs peuvent consulter l'historique de leurs conversations précédentes.",
      TOUR_CONFIG_TITLE: "Configuration de développement",
      TOUR_CONFIG__DESC: "En cliquant sur ce menu, vous accéderez à la page de configuration de développement.",
      TOUR_VALIDATOR_TITLE: "Validateur",
      TOUR_VALIDATOR__DESC: "Ce menu accède à la page Validateur, où les utilisateurs peuvent évaluer et valider l'exactitude des performances des réponses du chatbot.",
      TOUR_FEEDBACK_MGMT_TITLE: "Gestion des commentaires",
      TOUR_FEEDBACK_MGMT__DESC: `
      <p>Accédez aux pages suivantes en cliquant sur le menu Gestion des commentaires :</p>
  <ul>
    <li>Contenu</li>
    <li>Options de commentaires</li>
    <li>Questions et réponses sur le cache</li>
    <li>Commentaires des utilisateurs</li>
    <li>Rapports</li>
  </ul>`,
      TOUR_BOT_CONFIG_TITLE: "Configuration du robot",
      TOUR_BOT_CONFIG__DESC: "Ce menu permet d'accéder à la page Configuration du bot, où les utilisateurs peuvent configurer le modèle de discussion et d'autres paramètres associés.",
      TOUR_REPORTS_TITLE: "Rapports",
      TOUR_REPORTS__DESC: "En cliquant sur ce menu, vous accéderez à la page Rapports du bot.",
      TOUR_CHAT_LOADER_TITLE: "Chargeur",
      TOUR_CHAT_LOADER_DESC: "Le chargeur s'affichera jusqu'à ce que la réponse arrive",
      TOUR_CHAT_RESPONSE_TITLE: "Contenu de la réponse",
      TOUR_CHAT_RESPONSE_DESC: "La réponse apparaîtra en fonction de la question posée.",
       IEIIS_TITLE: "IE ou IIS",
      IEIIS_Description: "Cette bascule permet d'adapter les réponses du chatbot en fonction de l'option IE ou IIS sélectionnée.",
      USER_MGMT_TITLE: "Gestion des utilisateurs",
      USER_MGMT_DESE: "Ce menu permet d'accéder à la page Gestion des utilisateurs, où les attributions de rôles peuvent être gérées et où les modifications du profil utilisateur peuvent être apportées.",
    }
  }

  constructor() { }

  translateLabel(label: string, lang: string): string {
    if (!this.translations[lang] || !this.translations[lang][label]) {
      lang = 'en';
    }
    return this.translations[lang][label] || label;
  }
}
