<div class="modal" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-content">
        <div class="close-icon">
            <button mat-icon-button (click)="closeModal()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <!-- Add other content here -->
        <div class="head-center">
            <img alt="Rating Bot" src="assets/icon chatbot.svg" />
        </div>
        <div class="head-center" *ngIf="showFirstModal">
            <div>
                <h1 id="parent-modal-title">{{'rating.RATE_TITLE' | translate}}</h1>
                <div>{{'rating.RATE_TITLE_TEXT' | translate}}</div>
                <br>
            </div>
        </div>
        <div class="cont" *ngIf="showFirstModal">
            <div class="rating" (mouseleave)="reset()">
                <span *ngFor="let star of stars; let i = index" class="star" [class.filled]="i < currentRating"
                    (mouseenter)="enter(i+1)" (click)="setRating(i+1)">
                    <ng-container *ngIf="i < currentRating; else outline">&#9733;</ng-container>
                    <ng-template #outline>&#9734;</ng-template>
                </span>
            </div>
        </div>
        <div class="head-center"
            *ngIf="!showFirstModal && !this.isOthersField && !this.isNotCorrectField && !this.flagEnabled">
            <h1 id="child-modal-title">{{'rating.USER_COMMENT' | translate}}</h1>
            <div class="full-width">
                <mat-form-field>
                    <mat-label>{{'rating.SELECT_OPTION' | translate}}</mat-label>
                    <mat-select [(ngModel)]="userSelectedComments" (selectionChange)="onSelectionChange()">
                        <mat-option *ngFor="let option of validObjects" [value]="option.value">{{ option.label
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="full-width" *ngIf="chatBotResponse.responseType === 'out-of-scope'">
                    <mat-form-field>
                        <mat-label>{{'rating.SELECT_OPTION' | translate}}</mat-label>
                        <mat-select [(ngModel)]="userSelectedComments" (selectionChange)="onSelectionChange()">
                            <mat-option *ngFor="let option of nonValidObjects" [value]="option.value">{{ option.label
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="full-width" *ngIf="chatBotResponse.responseType === 'sensitive-question'">
                    <mat-form-field>
                        <mat-label>{{'rating.SELECT_OPTION' | translate}}</mat-label>
                        <mat-select [(ngModel)]="userSelectedComments" (selectionChange)="onSelectionChange()">
                            <mat-option *ngFor="let option of sensitiveObjects" [value]="option.value">{{ option.label
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            <div>
                <button (click)="saveFeedback()" [disabled]="!selectedOption" class="mui-button">{{'rating.SAVE' |
                    translate}}</button>
            </div>
        </div>
        <div class="head-center" *ngIf="flagEnabled">
            <h1 id="child-modal-title">{{'rating.ADD_NOT_CORRECT_COMMENT'| translate}}</h1>
            <div class="full-width">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{'rating.COMMENTS' | translate}}</mat-label>
                    <textarea class="text-area-width" [(ngModel)]="userTypedComments" rows="4" matInput
                        (input)="onTextareaInput($event)"></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20">
                <button (click)="goBack()" class="mui-button-clear">{{'rating.BACK' | translate}}</button>
                <button (click)="saveFeedback()" [disabled]="userTypedComments === ''"
                    class="mui-button">{{'rating.SAVE' | translate}}</button>
            </div>
        </div>
        <!-- <div class="head-center" *ngIf="this.isOthersField">
                <h2 id="child-modal-title">{{'rating.ADDITONAL_USER_COMMENT'| translate}}</h2>
                <div class="full-width">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>{{'rating.COMMENTS' | translate}}</mat-label>
                        <textarea class="text-area-width" [(ngModel)]="userTypedComments" rows="4"
                            matInput (input)="onTextareaInput($event)"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20">
                    <button (click)="goBack()" class="mui-button-clear">{{'rating.BACK' | translate}}</button>
                    <button (click)="saveFeedback()" [disabled]="userTypedComments === ''" class="mui-button">{{'rating.SAVE' | translate}}</button>
                </div>
            </div> -->
    </div>
</div>