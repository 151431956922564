<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <mat-accordion>
        <mat-expansion-panel [expanded]="isAccordianExpand" (opened)="onAccordionToggle(true)"
            (closed)="onAccordionToggle(false)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'chatBot.SEARCH_SECTION_TITLE' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <div *ngIf="roles.includes('chatanalyst')" fxLayout="row" fxLayoutGap="20px">
                    <div class="full-width">
                        <mat-label>{{ 'sideNav.CHAT_TRANSCRIPT_USR_TYPE' | translate }}:</mat-label>
                        <mat-radio-group [(ngModel)]="userType" (ngModelChange)="onUserTypeChange($event)">
                            <mat-radio-button value="all">{{ 'sideNav.CHAT_TRANSCRIPT_ALL' | translate
                                }}</mat-radio-button>
                            <mat-radio-button value="specified">{{ 'sideNav.CHAT_TRANSCRIPT_SPEC' | translate
                                }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="userType !== 'all'" class="full-width">
                        <div class="note font-size12" *ngIf="!shouldDisableEmail(roles)">
                            * {{ 'sideNav.EMAIL_NOTE' | translate }}
                        </div>
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>{{ 'sideNav.EMAIL' | translate }}</mat-label>
                            <input matInput type="email" [(ngModel)]="email" name="email" id="email" required
                                [disabled]="shouldDisableEmail(roles)">
                        </mat-form-field>

                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{ 'sideNav.START_DATE' | translate }}</mat-label>
                        <input matInput [matDatepicker]="startDatePicker" (ngModelChange)="validateDates()"
                            [(ngModel)]="startDate" name="startDate" id="startDate" [min]="minDate" [max]="today"
                            required>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{ 'sideNav.END_DATE' | translate }}</mat-label>
                        <input matInput [matDatepicker]="endDatePicker" (ngModelChange)="validateDates()"
                            [(ngModel)]="endDate" name="endDate" id="endDate" [min]="startDate" [max]="today" required>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="full-width" *ngIf="isSameDate(startDate, endDate)">
                        <mat-label>{{ 'sideNav.START_TIME' | translate }}</mat-label>
                        <input matInput class="time-picker" (change)="validateTimes()" [(ngModel)]="startTime"
                            type="time" id="startTime" required name="startTime" />
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="full-width" *ngIf="isSameDate(startDate, endDate)">
                        <mat-label>{{ 'sideNav.END_TIME' | translate }}</mat-label>
                        <input matInput class="time-picker" (change)="validateTimes()" [(ngModel)]="endTime" type="time"
                            id="endTime" required name="endTime" />
                    </mat-form-field>
                    <mat-error *ngIf="endTimeInvalid && isSameDate(startDate, endDate)"> * {{
                        'errorMessages.END_TIME_VALIDATION' | translate
                        }}</mat-error>
                </div>

                <!-- Time Picker, displayed only when start and end dates are the same -->
                <!-- <div fxLayout="row" fxLayoutGap="20px" *ngIf="isSameDate(startDate, endDate)">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{ 'sideNav.START_TIME' | translate }}</mat-label>
                        <input matInput class="time-picker" (change)="validateTimes()" [(ngModel)]="startTime"
                            type="time" id="startTime" required name="startTime" />
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{ 'sideNav.END_TIME' | translate }}</mat-label>
                        <input matInput class="time-picker" (change)="validateTimes()" [(ngModel)]="endTime" type="time"
                            id="endTime" required name="endTime" />
                    </mat-form-field>
                    <mat-error *ngIf="endTimeInvalid"> * {{ 'errorMessages.END_TIME_VALIDATION' | translate
                        }}</mat-error>
                </div> -->

                <div fxLayout="row" fxLayoutGap="20" fxLayoutAlign="end end">
                    <button mat-raised-button class="mui-button-clear" (click)="onClear()">{{ 'sideNav.CLEAR' |
                        translate
                        }}</button>
                    <button mat-raised-button class="mui-button" [disabled]="!isFormValid()" (click)="onSearchChat()">{{
                        'sideNav.SEARCH' | translate }}</button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br>
    <mat-accordion *ngIf="!isFilter">
        <mat-expansion-panel [expanded]="isAccordianExpand">
            <mat-expansion-panel-header>
                <mat-panel-title>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="table-content-fit-page" [style.height]="secondAccordionHeight">
                <div *ngIf="transcriptService.transcriptdataLoading; else dataLoaded" class="loading-bar-center">
                    <!-- Loader -->
                    <app-loader [botLoderType]="botLoderType"></app-loader>
                </div>
                <div class="no-data-message"
                    *ngIf="!transcriptService.transcriptdataLoading && (!transcriptData || transcriptData.length === 0)">
                    {{'contentMgmt.NO_RECORD_FOUND' | translate}}
                </div>

                <ng-template #dataLoaded>
                    <div class="table-container">
                        <table class="custom-tables" *ngIf="transcriptData && transcriptData.length > 0">
                            <thead>
                                <tr>
                                    <th>{{'sideNav.EMAIL' | translate}}</th>
                                    <th>{{'chatBot.CONVERSATION_TEXT' | translate}}</th>
                                    <th>{{'chatBot.CONVERSATION_DATE_TIME' | translate}}</th>
                                    <th>{{'chatBot.VIEW' | translate}}</th>
                                </tr>
                            </thead>
                            <!-- Table Body -->
                            <tbody>
                                <tr *ngFor="let conversation of transcriptData;  let index = index"
                                    [class.even-row]="index % 2 === 0" [class.odd-row]="index % 2 !== 0">
                                    <td>
                                        <span><img height="12px" alt='User Email' src='assets/account.svg' /></span>
                                        {{conversation.userID }}
                                    </td>
                                    <td>
                                        <div>
                                            <p *ngFor="let question of conversation.conversation">{{ question.question
                                                }}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="text-nowrap">{{
                                        dateFormatService.convertUtcToLocalUnderScore(conversation.startTime) }}</td>
                                    <td><img alt='Kumaran Systems Private Limited' class="cursor"
                                            (click)="openDrawer(conversation)" src='assets/eye 1.png' /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="pagination" *ngIf="transcriptData && transcriptData.length > 0">
                        <button (click)="onPageChange(1)" [disabled]="currentPage === 1">
                            <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
                        </button>
                        <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">
                            <mat-icon aria-hidden="false" aria-label="Previous Page"
                                fontIcon="navigate_before"></mat-icon>
                        </button>
                        <span>{{ currentPage }} / {{ totalTablePages }}</span>
                        <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalTablePages">
                            <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
                        </button>
                        <button (click)="onPageChange(totalTablePages)" [disabled]="currentPage === totalTablePages">
                            <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
                        </button>
                        <span class="export-excel-style" (click)="exportToExcel()">
                            <mat-icon aria-hidden="false" aria-label="Download" fontIcon="file_download"></mat-icon>
                        </span>
                    </div>
                    <app-conversation-drawer [selectedConversation]="selectedConversation"
                        (drawerClosed)="handleDrawerClosed()"></app-conversation-drawer>
                    <!-- No records found message -->

                </ng-template>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="isModalLoader" class="modal-backdrop">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>