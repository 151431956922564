<div class="page-containter"
[ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div fxLayout="row" fxLayoutGap="20" fxLayoutAlign="end center" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="10">
        <button mat-button class="mui-button" *ngIf="!isNewUser" (click)="addNewRow()">{{'rating.ADD_NEW_SYNONYMS' |
            translate}}</button>
        <button mat-button class="mui-button" *ngIf="isNewUser" (click)="cancelNewRow()">{{'cache.CANCEL' |
            translate}}</button>
        <mat-form-field appearance="fill">
            <mat-label>{{'sideNav.SEARCH' | translate}}</mat-label>
            <input matInput #searchInput [(ngModel)]="searchQuery" placeholder="{{'devConfig.ENTER_FOR'| translate}}" (keydown.enter)="searchTableData()">
            <button mat-icon-button matSuffix (click)="searchTableData()">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>clear</mat-icon>
            </button>

        </mat-form-field>
    </div>
    <div class="table-content-fit-page">
        <table class="custom-tables" *ngIf="data.length > 0; else loaderSection">
            <thead>
                <tr>
                    <th>{{'rating.PHRASE' | translate}}</th>
                    <th>{{'rating.MEANING' | translate}}</th>
                    <th>{{ 'rating.SYNONYMS_USAGE' | translate }}</th>
                    <th>{{'devConfig.ACTION' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr *ngFor="let row of data; let index = index" [class.even-row]="index % 2 === 0"
                        [class.odd-row]="index % 2 !== 0">
                        <td>
                            <mat-form-field appearance="fill" class="width-100-percent">
                                <mat-label>{{'rating.PHRASE' | translate}}</mat-label>
                                <input matInput #phraseInput #phrase="ngModel" [(ngModel)]="row.phrase" required
                                    [disabled]="!editMode[index]">
                                <mat-error *ngIf="phrase.invalid && phrase.touched">
                                    {{'errorMessages.SYNONYMS_PHRASE_MISSING'| translate}}
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill" class="width-100-percent">
                                <mat-label>{{'rating.MEANING' | translate}}</mat-label>
                                <textarea matInput #meaningInput #meaning="ngModel" [(ngModel)]="row.meaning" required
                                    [disabled]="!editMode[index]"></textarea>
                                <mat-error *ngIf="meaning.invalid && meaning.touched">
                                    {{'errorMessages.SYNONYMS_MEANING_MISSING'| translate}}
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill" class="width-100-percent">
                                <mat-label>{{ 'rating.SYNONYMS_USAGE' | translate }}</mat-label>
                                <mat-select #usage_type="ngModel" [(ngModel)]="row.usage_type" name="usage_type" required [disabled]="!editMode[index]">
                                  <mat-option [value]="0">{{ 'rating.SYNONYMS_QUERY_NA' | translate }}</mat-option>
                                  <mat-option [value]="1">{{ 'rating.SYNONYMS_QUERY' | translate }}</mat-option>
                                  <mat-option [value]="2">{{ 'rating.SYNONYMS_QUERY_MIGRATION' | translate }}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="usage_type.hasError('required')">
                                  {{ 'errorMessages.SYNONYMS_USAGE_MISSING' | translate }}
                                </mat-error>
                              </mat-form-field>
                        </td>
                        <td>
                            <div class="action-even-space">
                                <ng-container
                                    *ngIf="editMode[index]; then editModeEnabled else editModeDisabled"></ng-container>
                                <ng-template #editModeEnabled>
                                    <img src="assets/icons8-save-30.png" class="icon-button cursor" height="20"
                                        alt="Save Icon" (click)="handleSaveChanges(row, index)">
                                </ng-template>
                                <ng-template #editModeDisabled>
                                    <img src="assets/pen 1.svg" class="icon-button cursor" height="20" alt="Edit Icon"
                                        (click)="handleEditClick(row, index)" [class.disabled]="disableIcon">
                                </ng-template>
                                <span>
                                    <img src="assets/trash 1-black.svg" class="icon-button cursor" height="20"
                                        alt="Delete" (click)="handleDelete(row, index)" [class.disabled]="disableIcon">
                                </span>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <ng-template #loaderSection>
            <div class="loader-center-align">
                <div *ngIf="!isLoader">
                    {{'contentMgmt.NO_RECORD_FOUND' | translate}}
                </div>
            </div>
            <div class="loader-center-align" *ngIf="isLoader">
                <app-loader [botLoderType]="botLoderType"></app-loader>
            </div>
        </ng-template>
    </div>
    <div class="pagination" *ngIf="data && data.length > 0 && searchQuery ===''">
        <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
            <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
        </button>
        <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
            <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
        </button>
        <span>{{ pageNumber }} / {{ totalTablePage }}</span>
        <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage"
            [disabled]="pageNumber === totalTablePage">
            <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
        </button>
        <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage"
            [disabled]="pageNumber === totalTablePage">
            <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
        </button>
    </div>
</div>