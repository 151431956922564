<div class="confirmation-dialog" *ngIf="type ==='Confirmation'">
    <div fxLayout="row" fxLayoutAlign="end end">
        <button mat-icon-button (click)="closeModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start">
        <h1>{{ 'userComments.CONFIRM_ACTION' | translate }}</h1>
    </div>
    <b>
        <p [innerHTML]="message"></p>
    </b>
    <div>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>{{'errorMessages.VALIDATOR_COMMENT_REQ' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="userComments" rows="2"></textarea>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <button mat-button class="success_icon" (click)="confirm()">{{'feedback.APPROVE' |
            translate}}<mat-icon>check_circle_outline</mat-icon></button>
        <button mat-button class="reject_icon" (click)="cancel()">{{'feedback.REJECT' |
            translate}}<mat-icon>cancel</mat-icon></button>

    </div>
</div>

<div class="confirmation-dialog" *ngIf="type ==='View Record'">
    <h2>{{ title }}</h2>
    <div class="padding-20">
        <table mat-table [dataSource]="row" class="mat-elevation-z8 alternate-rows">
            <!-- Question Column -->
            <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef class="bold-header"> Question </th>
                <td mat-cell *matCellDef="let element"> {{ element.sensitiveQuestion }} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="bold-header"> Status </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.sensitiveQuestionStatus">
                        <mat-icon class="success_icon">check_circle_outline</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="!element.sensitiveQuestionStatus">
                        <mat-icon class="reject_icon">cancel</mat-icon>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['question', 'status']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['question', 'status'];" class="mat-row"></tr>
        </table>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="primary" (click)="confirm()">OK</button>
    </div>
</div>