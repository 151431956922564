import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../services/localStorageService';
import { ChatbotService } from "../services/chat.service";
import { DatePipe } from '@angular/common';
import { AuthService } from "../../../authServices";
import { ChatTranscriptService } from "../services/chat-transcript.service";
import { StateService } from '../services/shared-object.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentManagementService } from "../services/content-management.service";
import { TranslationService } from '../services/translation-service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ValidatorServiceService } from '../services/validator-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { DateFormatService } from '../pipe/date-time-localize';
import { filter } from 'rxjs/operators';
import { ProfileImageService } from '../pipe/removeProfileImageService';
interface AccordionStatus {
  currentWeek: boolean;
  currentMonth: boolean;
}
interface ChatCurrenWeektItem {
  startTime: string;
}
interface ChatCurrentMonthItem {
  startTime: string;
}
@Component({
  selector: 'app-chatsidenav',
  templateUrl: './chatsidenav.component.html',
  styleUrls: ['./chatsidenav.component.scss'],
})
export class ChatsidenavComponent implements OnInit {
  profileMenuVisible = false;
  userProfileName: string;
  firstName: string;
  lastName: string;
  roles: any[];
  accordionStatus: AccordionStatus = {
    currentWeek: true,
    currentMonth: true
  };
  chatCurrentData: ChatCurrenWeektItem[] = [];
  chatCurrentMonthData: ChatCurrenWeektItem[] = [];
  selectedItemIndex: number | null = null;

  email: string = '';
  startDate: any = '';
  endDate: any = '';
  startTime: any = null;
  endTime: any = null;
  page_size = 20;
  page = 1;
  selectedOption: string = 'file';
  validatorSelectedOption: string = 'csv';
  selectedFile: File | null = null;
  validationSelectedFile: File | null = null;
  fileInputRef: any;

  languageType: string = 'English';
  validatorlanguageType: string = 'English';
  boostScore: number = 4;
  contentName: string = '';
  fileType: any | '' = '';
  fileTypes = [
    { label: 'PDF', value: 'pdf' },
    { label: 'XLSX', value: 'xlsx' },
    { label: 'Word Document', value: 'docx' },
  ];
  fileUploaded: boolean = false;
  languageTypes: string[] = ['English', 'French'];
  boostScores: number[] = [1, 2, 3, 4, 5];
  htmlLink: string = '';
  websiteUrl: string = '';
  excludedValues: string[] = [];
  selectedExcludedValue: string[] = [];
  newExcludedValue: string = '';
  maxDate: Date;
  minDate: Date;
  translate: any;
  selectedLanguage: any = "";
  showNestedMenus: boolean = false;
  isMobile: boolean = false;
  isLoading: boolean = false;
  isLoadingWeek: boolean = false;
  isDisclosure: any = false;
  validatorFileName: string = "";
  historyFileName: string = "";
  validatorRunId: string = "";
  validatorOptions: any;
  @Output() actionClickedFromSideNav: EventEmitter<string> = new EventEmitter<string>();
  isShowFileName: boolean = false;
  fileNameError: boolean = false;
  validatorCmt: string = "";
  evaluationOption: string = 'bulk';
  imageRequired: any = false;
  maxHeight!: number;
  height!: number;
  profileImage = '';
  botLoderType = "boxLoader";
  isModalLoader: boolean = false;
  isEmailDisabled: boolean = false;
  botType: string = '';
  today: Date = new Date();
  userType: string = '';
  allUser: string = '';
  dynamicHeight: number = 260;
  hoursList: number[] = Array.from({ length: 24 }, (_, i) => i);
  endTimeInvalid: boolean = false;
  isExpandCollapse: boolean = true;
  constructor(
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private translated: TranslateService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private toastr: ToastrService,
    private sessionStorage: LocalStorageService,
    private elementRef: ElementRef,
    public chatbotService: ChatbotService,
    private datePipe: DatePipe,
    private authServices: AuthService,
    private transcriptService: ChatTranscriptService,
    private stateService: StateService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public contentMgmtService: ContentManagementService,
    private translateService: TranslationService,
    private breakpointObserver: BreakpointObserver,
    private validator: ValidatorServiceService,
    public dateFormatService: DateFormatService,
    private renderer: Renderer2,
    private removesProfileImage: ProfileImageService,
  ) {
    this.userProfileName = sessionStorage.get('username');
    this.firstName = this.userProfileName?.split('.')[0]?.toUpperCase();
    this.lastName = this.userProfileName?.split('.')[1].split('@')[0]?.toUpperCase();
    this.roles = sessionStorage.get('roles');
    const today = new Date();
    this.maxDate = today;
    this.minDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
    this.startDate = new Date();
    this.endDate = new Date();
    this.email = this.sessionStorage.get('username');
    this.translate = translateService;
    this.selectedLanguage = this.sessionStorage.get('language');
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

  }

  ngOnInit() {
    this.botType = this.sessionStorage.get('bot_type');
    let userImage = this.sessionStorage.get('profile_picture');
    this.profileImage = userImage;
    this.chatbotService.profilePictureUrl = userImage !== '' ? this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${userImage}`) : 'assets/noImage.png';
    document.addEventListener('click', this.onClickOutside.bind(this));

    this.translate.setLanguage(this.sessionStorage.get('language'));
    this.setHeightBasedOnViewport();
    if (this.roles?.includes('chatanalyst')) {
      this.userType = 'all';
    } else {
      this.userType = 'specified';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setHeightBasedOnViewport();
  }

  setHeightBasedOnViewport() {

    const windowWidth = window.innerWidth;
    if (windowWidth > 1000) {
      this.dynamicHeight = 300;
    } else if (windowWidth < 600) {
      this.dynamicHeight = 100;
    } else {
      this.dynamicHeight = 200;
    }
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onClickOutside.bind(this));
  }

  getCurrentWeekChat() {
    this.isLoadingWeek = true;
    let payload = { userID: this.userProfileName, bot_type: this.sessionStorage.get('bot_type') }
    this.chatbotService.getCureentWeekChatHistory(payload).subscribe(
      (currentWeekData: ChatCurrenWeektItem[]) => {
        this.isLoadingWeek = false;
        this.chatCurrentData = currentWeekData;
      },
      (error) => {
        this.isLoadingWeek = false;
      });
  }

  formatTime(time: string): string {
    const formattedTime = this.datePipe.transform(time, 'MM/dd/yyyy hh:mm a');
    return formattedTime || time; // return the original time if formatting fails
  }
  
  onClickOutside(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.profileMenuVisible = false;
    }

    // Collapse the dropdowns if a click outside is detected
    if (!event.target.closest('.chat-history-container')) {
      this.accordionStatus.currentWeek = true;
      this.accordionStatus.currentMonth = true;
    }
  }

  toggleProfileMenu(event: Event): void {
    event.stopPropagation();
    this.profileMenuVisible = !this.profileMenuVisible;
  }

  logout(): void {
    this.translated.get('login.LOGOUT_SUCESS_TEXT').subscribe((translatedText: string) => {
      this.toastr.success(translatedText);
    });
    this.chatbotService.logout();
    this.router.navigate(['/login']);
    this.sessionStorage.remove('appTo');
    this.sessionStorage.remove('bot_type');
    this.sessionStorage.remove('conversationID');
    this.sessionStorage.remove('profile_picture');
    this.sessionStorage.remove('roles');
    this.sessionStorage.remove('username');
    this.chatbotService.pref_lang_type = '';
    this.chatbotService.clearChatHistory();
    this.chatbotService.botRespponse = undefined;
  }

  startNewConversation(): void {
    if (this.chatbotService.botRespponse) {
      this.chatbotService.setModalLoader(true);
      this.chatbotService.inputDisabled = false;
      this.chatbotService.iEIisTypeValues = "IE";
      this.stateService.clearDashboard();
      this.stateService.setDisableInput(false);
      this.chatbotService.pref_lang__in_chat = false;
      this.chatbotService.chatbotConversationEnd({ "conversationID": this.sessionStorage.get('conversationID'), "boy_type": this.sessionStorage.get('bot_type') }).then((response) => {
        this.translated.get('successfullMessages.END_CONVERSATION_SUCCESS').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
        this.chatbotService.isResponseReceived = false;
        this.chatbotService.clearChatHistory();
        this.chatbotService.botCurrentResponse = {};
        this.chatbotService.botCurrentQuestion = "";
        this.chatbotService.pref_lang_type = 'en'
        const initialChatpaload = {
          language_type: this.sessionStorage.get('language'),
          query: "Hi",
          userId: this.sessionStorage.get('username'),
          bot_type: this.sessionStorage.get('bot_type')
        }
        this.chatbotService.initialChatResponse(initialChatpaload);

      }).catch((error) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      });
    }
  }

  goToChatbot(): void {
    this.stateService.changeSidenavMode('side');
    this.router.navigate(['/chatbot']);
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.chatbotService.validateValidSession();
  }
  gotoChatTranscript(): void {
    this.chatbotService.validateValidSession();
    this.chatbotService.getAppBotConfiguration();
    this.router.navigate(['/conversationhistory']);
  }
  gotoContentManagement(): void {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/contentmanagement']);
    this.chatbotService.getAppBotConfiguration();
  }
  // onSearch() {
  //   if (this.email !== "" && this.startDate !== "" && this.endDate !== "") {


  //     const startDate = new Date(this.startDate);
  //     startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());

  //     const endDate = new Date(this.endDate);
  //     endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
  //     this.allUser = this.userType === 'all' ? "" : this.email.toLowerCase();
  //     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //     const searchData = {
  //       user_string: this.userType === 'all' ? "" : this.email.toLowerCase(),
  //       start_date: startDate.toISOString().split('T')[0],
  //       end_date: endDate.toISOString().split('T')[0],
  //       bot_type: this.sessionStorage.get('bot_type'),
  //       language_type: this.sessionStorage.get('language'),
  //       start_hour: this.startTime,
  //       end_hour: this.endTime,
  //       timezone_offset: timeZone,
  //     }
  //     // this.actionClickedFromSideNav.emit('closeSideNav');
  //     this.transcriptService.updateTranscriptData([]);
  //     this.transcriptService.getChatTranscriptData(searchData, this.page_size, this.page).subscribe((transcriptResponse) => {
  //       if (Array.isArray(transcriptResponse) && transcriptResponse.length > 0) {
  //         this.transcriptService.updateTranscriptData(transcriptResponse);
  //       }
  //     }, (error) => {
  //       if (error.status === 401) {
  //         this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
  //           this.toastr.error(translatedText);
  //         });
  //         this.router.navigate(['/login']);
  //         sessionStorage.clear();
  //       } else if (error.status === 500) {
  //         this.translated.get('errorMessages.FAILD_LOAD_DATA').subscribe((translatedText: string) => {
  //           this.toastr.error(translatedText);
  //         });
  //       } else if (error.status === 409) {
  //         this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
  //           this.toastr.error(translatedText);
  //         });
  //         this.router.navigate(['/login']);
  //         sessionStorage.clear();
  //       } else if (error.status === 400) {
  //         if (error.error.startsWith('Search Limit Exceeds the Threshold :')) {
  //           this.translated.get('errorMessages.CHAT_TRANSCTIPT_MSG_INFOR').subscribe((translatedText: string) => {
  //             this.toastr.info(translatedText);
  //           });
  //         }
  //         this.transcriptService.updateTranscriptData([]);
  //       }
  //     })
  //     this.stateService.emitSearchData({ email: this.allUser, startDate: this.startDate, endDate: this.endDate, page: this.page, pageSize: this.page_size, startTime: this.startTime, endTime: this.endTime });
  //   } else if (this.email === "") {
  //     this.translated.get('errorMessages.EMAIL_EMPTY_ERROR').subscribe((translatedText: string) => {
  //       this.toastr.error(translatedText);
  //     });
  //   } else if (this.startDate === "") {
  //     this.translated.get('errorMessages.START_DATE_EMPTY').subscribe((translatedText: string) => {
  //       this.toastr.error(translatedText);
  //     });
  //   } else if (this.endDate === "") {
  //     this.translated.get('errorMessages.END_DATE_EMPTY').subscribe((translatedText: string) => {
  //       this.toastr.error(translatedText);
  //     });
  //   }
  // }

  // onClear() {
  //   if (this.email !== "" || this.startDate !== "" || this.endDate !== "") {
  //     this.email = '';
  //     this.startDate = '';
  //     this.endDate = '';
  //     this.transcriptService.updateTranscriptData([]);
  //   } else {
  //     this.translated.get('errorMessages.CLEAR_ERROR_CONTENT_MGMT').subscribe((translatedText: string) => {
  //       this.toastr.error(translatedText);
  //     });
  //   }
  // }

  preventDefault(e: any) {
    e.preventDefault();
  }

  formatSize(size: number | undefined): string {
    if (size === undefined) return '';
    const KB = 1024;
    const MB = KB * KB;
    if (size < KB) return size + ' B';
    else if (size < MB) return (size / KB).toFixed(2) + ' KB';
    else return (size / MB).toFixed(2) + ' MB';
  }

  goToSynonyms() {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/synonyms']);
    // this.chatbotService.validateValidSession();
    this.chatbotService.getAppBotConfiguration();
  }
  gotoUserManagement() {
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/usermanagement']);
  }
  goToDevConfig() {
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/apiconfiguration']);
    this.chatbotService.getAppBotConfiguration();
  }
  gotoRatingTypeOption() {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/feedbackcomment']);
    this.chatbotService.validateValidSession();
    this.chatbotService.getAppBotConfiguration();
  }

  gotoCache() {
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/cache']);
    this.chatbotService.validateValidSession();
    this.chatbotService.getAppBotConfiguration();
  }
  gotoConfiguration() {
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/configuration']);
  }
  goToValidation() {
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/validation']);
    this.chatbotService.validateValidSession();
    this.chatbotService.getAppBotConfiguration();
  }
  gotoChitchat() {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/addchitchat']);
  }

  gotoUserComments() {
    // Change the sidenav mode to 'side' using the service
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/user-comments']);
    this.chatbotService.validateValidSession();
    this.chatbotService.getAppBotConfiguration();
  }
  gotoReports() {
    this.stateService.changeSidenavMode('side');
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/reports']);
  }
  gotoChitchatYetToApprove() {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/yettoapprove']);
  }
  gotoChitchatApprove() {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/approved']);
  }
  gotoChitchatRejected() {
    // this.actionClickedFromSideNav.emit('closeSideNav');
    this.router.navigate(['/rejected']);
  }
  changeProfileImage() {
    this.openPopUpDialog();
  }
  removeProfileImage() {
    this.removesProfileImage.removeProfileImage('');
  }
  changePassword() {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        isPasswordReset: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      const payload = {
        username: this.sessionStorage.get('username'),
        current_password: result?.value?.currentPassword,
        new_password: result?.value?.newPassword,
        bot_type: this.sessionStorage.get('bot_type')
      }
      this.chatbotService.changePassword(payload).subscribe((res: any) => {
        if (res.message) {
          this.translated.get('successfullMessages.UPDATED_PASSWORD').subscribe((translatedText: string) => {
            this.toastr.success(translatedText);
          });
          this.logout();
        }
      }, (error: any) => {

        if (error?.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error?.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error?.status === 400) {
          if (error?.error?.message === 'Current password cannot be same as previous.') {
            const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
              width: 'auto',
              disableClose: true,
              data: {
                passwordNotSamePrevious: true,
              },
            });

            dialogRef.afterClosed().subscribe(result => {
            });
          } else if (error?.error?.message === 'New password does not meet the required criteria') {
            const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
              width: 'auto',
              disableClose: true,
              data: {
                passwordNotValid: true,
              },
            });

            dialogRef.afterClosed().subscribe(result => {
            });
          }
        }
      })
    });
  }
  openPopUpDialog() {
    const translatedMessage = this.translateLabel('PROFILE_PICTURE_UPLOAD').translation;
    const message: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(translatedMessage);

    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 2, message: message, disable: true,
        options: []
      } // Pass the sanitized message
    });

    dialogRef.afterClosed().subscribe((result: File) => {
      if (result) {
        const formData = new FormData();
        formData.append('username', this.sessionStorage.get('username'));
        formData.append("profile_picture", result);
        formData.append('bot_type', this.sessionStorage.get('bot_type'));
        this.isModalLoader = true;
        this.chatbotService.uploadProfilePicture(formData).then((response: any) => {
          this.isModalLoader = false;
          this.translated.get('errorMessages.PROFILE_IMG_UPDATED').subscribe((translatedText: string) => {
            this.toastr.success(translatedText);
          });
          this.sessionStorage.remove('profile_picture');
          this.chatbotService.profilePictureUrl = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${response.profile_picture}`);
          // this.userProfile = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${response.profile_picture}`);
          this.sessionStorage.set('profile_picture', response.profile_picture);
          this.profileImage = response.profile_picture;

        }).catch((error: any) => {
          this.isModalLoader = false;
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          } else if (error.status === 400) {
            this.translated.get('errorMessages.INVALID_IMG_FORMAT').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
          }
        })
      }
    });
  }

  languageChanged() {
    this.openLogoutDialog();
  }

  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.chatbotService.pref_lang_type || 'en';
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
  openLogoutDialog() {
    const translatedMessage = this.translateLabel('LANGUAGE_CONFIRMATION').translation;
    const message: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(translatedMessage);

    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 3, message: message,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe((result: File) => {
      if (result) {
        const formData = new FormData();
        formData.append('username', this.sessionStorage.get('username'));
        formData.append('language', this.selectedLanguage);
        formData.append('bot_type', this.sessionStorage.get('bot_type'));
        this.chatbotService.saveLanguage(formData).subscribe((response: any) => {
          this.translated.get('successfullMessages.LANGUAGE_UPDATED_SUCCESS').subscribe((translatedText: string) => {
            this.toastr.success(translatedText);
          });
          // this.actionClickedFromSideNav.emit('closeSideNav');
          setTimeout(() => {
            this.logout();
          }, 2000);
        },
          (error: any) => {
            if (error.status === 401) {
              this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
                this.toastr.error(translatedText);
              });
              this.router.navigate(['/login']);
              this.sessionStorage.clear();
            }
          })

      } else if (result === false) {
        this.selectedLanguage = this.sessionStorage.get('language');
      }
    });
  }
  toggleNestedMenu() {
    this.showNestedMenus = !this.showNestedMenus;
  }

  isAnyMenuItemPresent(): boolean {
    return !this.isChatbotPage() && this.roles && (this.roles.includes('chatanalyst') || this.roles.includes('user'))
      || (this.roles && this.roles.includes('chatanalyst') && !this.isChatConversationHistory())
      || (this.roles && this.roles.includes('configadmin'))
      || (this.roles && this.roles.includes('contentmanager'))
      || (this.roles && this.roles.includes('useradmin'));
  }

  showFileName() {
    // this.isShowFileName = !this.isShowFileName;
    this.validatorFileName = '';
  }

  handleDisableUser() {
    return this.sessionStorage.get('roles').includes('chatanalyst')
  }

  isAllRolesPresent(): boolean {
    const requiredRoles = ["user", "chatanalyst", "contentmanager", "configadmin", "useradmin", "evaluator", "botadmin"];
    return requiredRoles.every(role => this.roles.includes(role));
  }
  closeProfileMenu(): void {
    this.profileMenuVisible = false;
  }

  checkUserRoles() {
    const roles = this.sessionStorage.get('roles');
    this.isEmailDisabled = this.shouldDisableEmail(roles);
  }

  shouldDisableEmail(roles: string[]): boolean {
    return (!roles?.includes('chatanalyst'));
  }

  // isSameDate(date1: Date, date2: Date): boolean {
  //   if (!date1 || !date2) {
  //     return false;
  //   }
  //   return date1.getFullYear() === date2.getFullYear() &&
  //     date1.getMonth() === date2.getMonth() &&
  //     date1.getDate() === date2.getDate();
  // }

  // onUserTypeChange(event: any) {
  //   const windowWidth = window.innerWidth;
  // }

  // validateDates() {
  //   // Reset start and end times if dates are not the same
  //   if (!this.isSameDate(this.startDate, this.endDate)) {
  //     this.startTime = null;
  //     this.endTime = null;
  //   }
  // }

  // validateTimes() {
  //   if (this.endTime && this.startTime && this.endTime < this.startTime) {
  //     this.endTimeInvalid = true;
  //   } else {
  //     this.endTimeInvalid = false;
  //   }
  // }
  // // Check form validity
  // isFormValid(): boolean {
  //   // Check if the basic fields are filled (email, startDate, endDate) and if endTime is not invalid
  //   if (!this.email || !this.startDate || !this.endDate || this.endTimeInvalid) {
  //     return false;
  //   }

  //   // Ensure endDate is not earlier than startDate
  //   if (this.endDate < this.startDate) {
  //     return false;
  //   }

  //   // If the dates are the same, require both startTime and endTime to be selected
  //   if (this.isSameDate(this.startDate, this.endDate)) {
  //     return this.startTime !== null && this.endTime !== null;
  //   }

  //   // If the dates are not the same, times are not required
  //   return true;
  // }

  expandCollapse() {
    this.isExpandCollapse = !this.isExpandCollapse;
    this.stateService.setExpandCollapseState(this.isExpandCollapse);
  }

  isLoginPage(): boolean {
    return this.router.url === '/login';
  }
  isChatbotPage(): boolean {
    return this.router.url === '/chatbot'
  }
  isConversationHistoryPage(): boolean {
    return this.router.url === '/conversationhistory'
  }
  isContentManagementPage(): boolean {
    return this.router.url === '/contentmanagement'
  }
  isPasswordResetPage(): boolean {
    return this.router.url.includes('/passwordreset');
  }
  isThankYouPage(): boolean {
    return this.router.url === '/thankyou';
  }
  isSynonymsPage(): boolean {
    return this.router.url === '/synonyms';
  }
  isConfigurationPage(): boolean {
    return this.router.url === "/apiconfiguration"
  }
  isUserManagementPage(): boolean {
    return this.router.url === "/usermanagement"
  }
  isFeedbackPage(): boolean {
    return this.router.url === "/feedbackcomment"
  }

  isCacheTopicPage(): boolean {
    return this.router.url === "/cache"
  }
  isConfigTopicPage(): boolean {
    return this.router.url === "/configuration"
  }
  isChatHistoryPage(): boolean {
    return this.router.isActive("/chathistory", false);
  }
  isValidationPage(): boolean {
    return this.router.url === "/validation"
  }
  isChitChatPage() {
    return this.router.url === "/addchitchat"
  }
  isUserComments() {
    return this.router.url === "/user-comments"
  }
  isreportsPage() {
    return this.router.url === "/reports"
  }

  isChatbotUrl(): boolean {
    return this.router.url.includes('/chatbot');
  }

  isChatBotChatHistory(): boolean {
    return this.router.url.includes('/chathistory') || this.router.url.includes('/chatbot');
  }

  isChatConversationHistory(): boolean {
    return this.router.url.includes('/conversationhistory');
  }
  isValidatorpage(): boolean {
    return this.router.url.includes('/validation');
  }
  isContentManagement(): boolean {
    return this.router.url.includes('/contentmanagement');
  }
  isSynonyms(): boolean {
    return this.router.url.includes('/synonyms');
  }
  isFeedback(): boolean {
    return this.router.url.includes('/feedbackcomment');
  }

  isCache(): boolean {
    return this.router.url.includes('/cache');
  }
  isConfiguration(): boolean {
    return this.router.url.includes('/configuration');
  }
  isChitChat(): boolean {
    return this.router.url.includes('/addchitchat')
  }
  isValidator() {
    return this.router.url.includes('/validation');
  }
  isUserManagement() {
    return this.router.url.includes('/usermanagement');
  }
  isAPIConfiguration() {
    return this.router.url.includes('/apiconfiguration');
  }
  isReports() {
    return this.router.url.includes('/reports');
  }

  tourGuideStart() {
    this.stateService.startTour()
  }

}
