<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div *ngIf="!isNewRow && !reorderEnabled" fxLayout="row" fxLayoutAlign="end center" fxLayout.xs="column"
        fxLayoutGap="20px">
        <mat-form-field appearance="fill" *ngIf="!reorderEnabled">
            <mat-label>{{'feedback.RESPONSE_TYPE' | translate}}</mat-label>
            <mat-select [(ngModel)]="responseType" (ngModelChange)="filterDataByApplication()" required>
                <mat-option *ngFor="let response of targetTypeOptions" [value]="response">{{ response }}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-button class="mui-button" [disabled]="!responseType"
            (click)="searchFeedback(this.pageNumber, this.pageSize)">{{'sideNav.SEARCH'
            | translate}}</button>
        <button mat-button class="mui-button" (click)="addNewRow()">{{'sideNav.ADD' |
            translate}}</button>
        <button mat-button class="mui-button" [disabled]="responseType ==='All'"
            (click)="reorder()">{{'feedback.REORDER' |
            translate}}</button>
    </div>
    <div *ngIf="reorderEnabled" fxLayout="row" fxLayoutAlign="end center" fxLayout.xs="column" fxLayoutGap="20px">
        <mat-form-field appearance="fill">
            <mat-label>{{'feedback.RESPONSE_TYPE' | translate}}</mat-label>
            <mat-select [(ngModel)]="responseType" (ngModelChange)="responseTypeChange()" required>
                <mat-option *ngFor="let response of responseTypeOptions" [value]="response">{{ response }}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <button mat-button class="mui-button" [disabled]="!responseType"
            (click)="searchFeedbackList()">{{'sideNav.SEARCH'
            | translate}}</button> -->
        <button mat-button class="mui-button-clear" (click)="cancelReorder()">{{'devConfig.CANCEL'
            | translate}}</button>
        <button mat-button class="mui-button" [disabled]="!reorderProcess" (click)="reorderSave()">{{'cache.SAVE' |
            translate}}</button>
    </div>
    <div class="table-content-fit-page" *ngIf="!isNewRow">
        <div class="table-container full-width">
            <table mat-table [dataSource]="data" #table class="alternate-rows full-width" cdkDropList
                (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!showPositionColumn"
                *ngIf="data.length > 0; else loaderSection">

                <ng-container matColumnDef="position" *ngIf="showPositionColumn">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.POSITION' | translate }}</th>
                    <td mat-cell *matCellDef="let row;let index = index" cdkDragHandle>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div *ngIf="index === 0"> <!-- First row -->
                                <mat-icon class="dragCursor" [matTooltip]="'feedback.REORDER_HEADING' | translate">
                                    arrow_downward
                                </mat-icon>
                            </div>
                            <div *ngIf="index > 0 && index < data.length - 1"> <!-- Middle rows -->
                                <mat-icon class="dragCursor" [matTooltip]="'feedback.REORDER_HEADING' | translate">
                                    <img height="20px" alt="reordering image" src="assets/reordering.png" />
                                </mat-icon>
                            </div>
                            <div *ngIf="index === data.length - 1"> <!-- Last row -->
                                <mat-icon class="dragCursor" [matTooltip]="'feedback.REORDER_HEADING' | translate">
                                    arrow_upward
                                </mat-icon>
                            </div>
                            <div>{{row.originalIndex}}</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="targetType">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.TARGET_TYPE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!editMode[row._id]; else editModeDisabledTarget">
                            <div>{{formatFeedbackType(row.feedback_type)}}</div>
                        </ng-container>
                        <ng-template #editModeDisabledTarget>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'feedback.TARGET_TYPE' |
                                    translate}}</mat-label>
                                <mat-select [(ngModel)]="row.feedback_type" (click)="$event.stopPropagation()"
                                    (ngModelChange)="targetTypeChange()" [disabled]="!editMode[row._id]" required>
                                    <ng-container *ngFor="let type of targetTypeOptions">
                                        <mat-option *ngIf="type !== 'All'" [value]="type">{{ type }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="responseType">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.RESPONSE_TYPE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!editMode[row._id]; else editModeDisabledTarget">
                            <div>{{formatFeedbackType(row.response_type)}}</div>
                        </ng-container>
                        <ng-template #editModeDisabledTarget>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'feedback.RESPONSE_TYPE' |
                                    translate}}</mat-label>
                                <mat-select [(ngModel)]="row.response_type" (click)="$event.stopPropagation()"
                                    (ngModelChange)="targetTypeChange()" [disabled]="!editMode[row._id]" required>
                                    <ng-container *ngFor="let type of targetTypeOptions">
                                        <mat-option *ngIf="type !== 'All'" [value]="type">{{ type }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="feedbackCommentEnglish">
                    <th mat-header-cell *matHeaderCellDef class="width300 head-bold">
                        <div>{{'feedback.FEEDBACK_CMD_LABEL_ENGLISH' | translate }}</div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!editMode[row._id]; else editModeDisabledTarget">
                            <div class="width300 text-eclipse cursor">{{row.feedback_comment_english}}
                            </div>
                        </ng-container>
                        <ng-template #editModeDisabledTarget>
                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'feedback.FEEDBACK_CMD_LABEL_ENGLISH' | translate
                                    }}</mat-label>
                                <textarea matInput (click)="$event.stopPropagation()"
                                    (input)="checkWordCount('english',row,row.feedback_comment_english)"
                                    [(ngModel)]="row.feedback_comment_english" [disabled]="!editMode[row._id]"
                                    required> </textarea>
                            </mat-form-field>
                        </ng-template>
                        <div class="error-message" *ngIf="row.wordCountErrorMessageEnglish">{{
                            row.wordCountErrorMessageEnglish }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="feedbackCommentFrench">
                    <th mat-header-cell *matHeaderCellDef class="width300 head-bold">{{
                        'feedback.FEEDBACK_CMD_LABEL_FRENCH' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!editMode[row._id]; else editModeDisabledTarget">
                            <div class="width300 text-eclipse cursor">{{row.feedback_comment_french}}
                            </div>
                        </ng-container>
                        <ng-template #editModeDisabledTarget>
                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'feedback.FEEDBACK_CMD_LABEL_FRENCH' | translate
                                    }}</mat-label>
                                <textarea matInput (click)="$event.stopPropagation()"
                                    (input)="checkWordCount('french',row,row.feedback_comment_french); validateFrenchCharacters(row.feedback_comment_french, row)"
                                    [(ngModel)]="row.feedback_comment_french" [disabled]="!editMode[row._id]"
                                    required> </textarea>
                            </mat-form-field>
                        </ng-template>

                        <div class="error-message" *ngIf="row.wordCountErrorMessageFrench">{{
                            row.wordCountErrorMessageFrench }}</div>
                        <div class="error-message" *ngIf="row.frenchErrorMessage">{{ row.frenchErrorMessage }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="additionalFeedbackComment">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.ADDITIONAL_FEEDBACK_COMMENT' |
                        translate }}</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!editMode[row._id]; else editModeDisabled">
                            <div>{{row.additional_comments_required }}</div>
                        </ng-container>
                        <ng-template #editModeDisabled>
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'feedback.ADDITIONAL_FEEDBACK_COMMENT' |
                                    translate }}</mat-label>
                                <mat-select [(ngModel)]="row.additional_comments_required"
                                    (ngModelChange)="flagValueChanged($event)" [disabled]="!editMode[row._id]"
                                    (click)="$event.stopPropagation()" required>
                                    <mat-option [value]=true>{{'feedback.TRUE' | translate}}</mat-option>
                                    <mat-option [value]=false>{{'feedback.FALSE' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="feedbackFlag">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.FEEDBACK_FLAG' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container>
                            <ng-container *ngIf="!editMode[row._id]; else editModeDisabled">
                                <div *ngIf="row.feedback_type?.toLowerCase() === 'sensitive'">{{row.feedback_flag}}
                                </div>
                                <div *ngIf="row.feedback_type?.toLowerCase() !== 'sensitive'">N/A</div>
                            </ng-container>
                            <ng-template #editModeDisabled>
                                <div *ngIf="row.feedback_type?.toLowerCase() !== 'sensitive'">N/A</div>
                                <mat-form-field appearance="outline"
                                    *ngIf="row.feedback_type?.toLowerCase() === 'sensitive'">
                                    <mat-label>{{ 'feedback.FEEDBACK_FLAG' | translate
                                        }}</mat-label>
                                    <mat-select [(ngModel)]="row.feedback_flag"
                                        (ngModelChange)="feedbackFlagChange($event, row)"
                                        [disabled]="!editMode[row._id]" required>
                                        <mat-option *ngFor="let option of feedbackType" [value]="option.value"
                                            (click)="$event.stopPropagation()">
                                            {{ option.label | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-template>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dateTime">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.DATE_TIME' | translate }}</th>
                    <td mat-cell *matCellDef="let row" class="text-nowrap">
                        {{ dateFormatService.convertUtcToLocalSlash(row.updated_timestamp) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'devConfig.ACTION' | translate }}</th>
                    <td mat-cell *matCellDef="let row; let rowIndex = index;">
                        <div class="action-even-space">
                            <ng-container *ngIf="editMode[row._id]; else editModeDisabled">
                                <button mat-button class="mui-button-clear"
                                    (click)="cancelEditMode(row, rowIndex);$event.stopPropagation()">{{'devConfig.CANCEL'
                                    |
                                    translate}}</button>
                                <button mat-icon-button (click)="handleSaveChanges(row, rowIndex);;$event.stopPropagation()">
                                    <img src="assets/icons8-save-30.png" height="10" class="icon-button cursor"
                                        alt="Save Icon"
                                        [class.disabled]="!row.feedback_comment_english || !row.feedback_comment_french
                                        || row.frenchErrorMessage || row.wordCountErrorMessageFrench || row.wordCountErrorMessageEnglish">
                                </button>
                            </ng-container>
                            <ng-template #editModeDisabled>
                                <button mat-icon-button
                                    (click)="handleEditClick(row, rowIndex);$event.stopPropagation()"
                                    [class.disabled]="disableIcon">
                                    <img src="assets/pen 1.svg" height="10" class="icon-button cursor"
                                        [class.disabled]="disableIcon" alt="Edit Icon">
                                </button>
                            </ng-template>
                            <button mat-icon-button (click)="handleDelete(row, rowIndex); $event.stopPropagation()"
                                [class.disabled]="disableIcon">
                                <img src="assets/trash 1-black.svg" height="10" class="icon-button cursor"
                                    alt="Delete Icon" [class.disabled]="disableIcon">
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngStyle]="{'height': '10px !important'}"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></tr> -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i=index"
                    [ngStyle]="{ 'background-color': (i % 2 === 0) ? '#f5f5f5' : 'white', 'height': '10px !important' }"
                    [cdkDragDisabled]="!showPositionColumn" cdkDrag (click)="onRowClick(row)" class="cursor">
                    <!-- <td *matCellDef="let row; let index = index" cdkDragHandle></td> -->
                </tr>
            </table>
        </div>
        <ng-template #loaderSection>
            <app-loader class="loader-center-align" *ngIf="isLoader" [botLoderType]="botLoderType"></app-loader>
            <div *ngIf="data.length === 0 && !isLoader" class="loader-center-align">{{ 'contentMgmt.NO_RECORD_FOUND' |
                translate }}</div>
        </ng-template>
        <div class="pagination" *ngIf="data && data.length > 0 && !reorderEnabled">
            <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
                <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
            </button>
            <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
                <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
            </button>
            <span>{{ pageNumber }} / {{ totalTablePage }}</span>
            <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage"
                [disabled]="pageNumber === totalTablePage">
                <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
            </button>
            <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage"
                [disabled]="pageNumber === totalTablePage">
                <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="isNewRow" class="page-new-container">
        <div class="padding-10 new_row_container">
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'feedback.TARGET_TYPE' | translate}}</mat-label>
                <mat-select #addTargetValue="ngModel" name="addTargetType" [(ngModel)]="addTargetType"
                    (ngModelChange)="filterDataByApplication()" required>
                    <ng-container *ngFor="let rating of targetTypeOptions">
                        <mat-option *ngIf="rating !== 'All'" [value]="rating">{{ rating }}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="addTargetValue.invalid && (addTargetValue.dirty || addTargetValue.touched)">
                    {{ 'errorMessages.REQUIRED_FEEDBACK_TYPE' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'feedback.RESPONSE_TYPE' | translate}}</mat-label>
                <mat-select #responseType="ngModel" name="responseType" [(ngModel)]="addResponseType"
                    (ngModelChange)="filterDataByApplication()" required>
                    <ng-container *ngFor="let rating of targetTypeOptions">
                        <mat-option *ngIf="rating !== 'All'" [value]="rating">{{ rating }}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="responseType.invalid && (responseType.dirty || responseType.touched)">
                    {{ 'errorMessages.REQUIRED_RESPONSE_TYPE' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'feedback.FEEDBACK_VALUE_ENGLISH' | translate}}</mat-label>
                <textarea #englishFeedback="ngModel" name="englishFeedback" matInput [(ngModel)]="feedbackValuesEnglish"
                    placeholder="{{'devConfig.VALUE'| translate}}" required (input)="checkWordCount('english')"></textarea>
                <button mat-icon-button matSuffix (click)="clear('english')">
                    <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="englishFeedback.invalid && (englishFeedback.dirty || englishFeedback.touched)">
                    {{ 'errorMessages.REQUIRED_EN_CMD' | translate }}
                </mat-error>
                <div class="error-message" *ngIf="wordCountErrorMessage">{{ wordCountErrorMessage }}</div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'feedback.FEEDBACK_VALUE_FRENCH' | translate}}</mat-label>
                <textarea #frenchFeedback="ngModel" name="frenchFeedback" matInput [(ngModel)]="feedbackValuesFrench"
                    placeholder="{{'devConfig.VALUE' | translate}}" required
                    (input)="checkWordCount('french'); validateFrenchCharacters()" #frenchInput="ngModel"
                    #frenchTextarea></textarea>
                <button mat-icon-button matSuffix (click)="clear('french', frenchTextarea)">
                    <mat-icon>clear</mat-icon>
                </button>
                <mat-error *ngIf="frenchFeedback.invalid && (frenchFeedback.dirty || frenchFeedback.touched)">
                    {{ 'errorMessages.REQUIRED_FR_CMD' | translate }}
                </mat-error>
                <!-- <div class="error-message" *ngIf="frenchErrorMessage">{{ frenchErrorMessage }}</div> -->
            </mat-form-field>
    
            <mat-form-field appearance="outline" matTooltip="{{'feedback.TOOLTIP' | translate}}" class="width-100-percent">
                <mat-label>{{'feedback.ADDITIONAL_FEEDBACK_COMMENT' |
                    translate}}</mat-label>
                <mat-select [(ngModel)]="additionalFeedbackComment" required>
                    <mat-option [value]="true">{{'feedback.TRUE' | translate}}</mat-option>
                    <mat-option [value]="false">{{'feedback.FALSE' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="addTargetType === 'Sensitive'" appearance="outline"
                matTooltip="{{'feedback.TOOLTIP_Feedback_Type' | translate}}" class="width-100-percent">
                <mat-label>{{'feedback.FEEDBACK_FLAG' | translate}}</mat-label>
                <mat-select [(ngModel)]="feedbackFlag" required>
                    <mat-option [value]="1">{{'feedback.TRUE' | translate}}</mat-option>
                    <mat-option [value]="0">{{'feedback.FALSE' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                <button mat-button class="mui-button-clear" (click)="cancel()">{{'devConfig.CANCEL' |
                    translate}}</button>
                <button mat-button class="mui-button" [disabled]="!validFeedbackFrom()"
                    (click)="addNewRecord()">{{'rating.SAVE' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop" *ngIf="isModalLoader">
    <app-loader [botLoderType]="botLoderType"></app-loader>
</div>