<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div fxLayout="row" fxLayoutGap="20" fxLayoutAlign="end center" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="10">
        <button mat-button class="mui-button" *ngIf="!isNewUser" [disabled]="isEditEnabled || data?.length === 0"
            (click)="addNewRow()">{{'rating.ADD_NEW_USER' | translate}}</button>
        <button mat-button class="mui-button" *ngIf="isNewUser" (click)="cancelAddRow()">{{'devConfig.CANCEL' |
            translate}}</button>
        <mat-form-field appearance="fill">
            <mat-label>{{'sideNav.SEARCH' | translate}}</mat-label>
            <input matInput #searchInput [(ngModel)]="searchQuery" placeholder="{{'devConfig.ENTER_FOR'| translate}}"
                (keydown.enter)="searchTableData()">
            <button mat-icon-button matSuffix (click)="searchTableData()">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>clear</mat-icon>
            </button>

        </mat-form-field>
    </div>
    <div class="table-content-fit-page">
        <div *ngIf="isLoader" class="loader-center-align">
            <app-loader [botLoderType]="botLoderType"></app-loader>
        </div>
        <div class="table-container">
            <table class="custom-tables" *ngIf="!isLoader && data.length > 0">
                <thead>
                    <tr>
                        <th>{{ 'rating.PROFILE_IMG' | translate }}</th>
                        <th>{{ 'rating.USERNAME' | translate }}</th>
                        <th>{{ 'rating.ROLES' | translate }}</th>
                        <th>{{ 'devConfig.ACTION' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr *ngFor="let row of data; let index = index" [class.even-row]="index % 2 === 0"
                            [class.odd-row]="index % 2 !== 0">
                            <td>
                                <div *ngIf="!editMode[index]">
                                    <img [src]="row.profilePictureUrl" alt="Profile Picture" height="30"
                                        class="profile-border-radius50" />
                                </div>
                                <div *ngIf="editMode[index]">
                                    <div
                                        *ngIf="!row.profilePictureUrl || row.profilePictureUrl === 'assets/noImage.png' || removesProfileImage.removedProfile">
                                        <input type="file" #fileInput accept="image/*" style="display: none"
                                            (change)="onFileSelected(fileInput)">
                                        <button mat-raised-button class="mui-button" [disabled]="selectedFile?.name"
                                            (click)="fileInput.click()">
                                            <mat-icon>cloud_upload</mat-icon>
                                            <span>{{ 'sideNav.UPLOAD_PROFILE_IMG' | translate }}</span>
                                        </button>
                                    </div>
                                    <div *ngIf="selectedFile">
                                        <p>{{ 'rating.SELECTED_FILE' | translate }}: {{ selectedFile.name }}</p>
                                    </div>
                                    <div
                                        *ngIf="row.profilePictureUrl && row.profilePictureUrl !== 'assets/noImage.png' && !removesProfileImage.removedProfile && !selectedFile">
                                        <button class="mui-button" mat-button (click)="removeProfileImage(row)">
                                            {{'rating.REMOVE_PROFILE' | translate}}
                                        </button>

                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <mat-form-field appearance="fill" class="width-100-percent">
                                        <mat-label *ngIf="botType ==='cibc'">{{'rating.USER_NAME_CIBC_EMAIL' |
                                            translate}}</mat-label>
                                        <mat-label *ngIf="botType !=='cibc'">{{'rating.USER_NAME_EMAIL' |
                                            translate}}</mat-label>
                                        <input matInput #userNameInput [(ngModel)]="row.username"
                                            [disabled]="!(isNewUser && index === 0) && !(editMode[index] && isNewUser)"
                                            name="username" required email #email="ngModel"
                                            (ngModelChange)="checkEmailValid(email)">
                                        <mat-error *ngIf="emailControl.hasError('invalidEmail')">
                                            <span *ngIf="botType !=='cibc'">{{ 'errorMessages.VALID_EMAIL_ERROR' |
                                                translate }}</span>
                                            <span *ngIf="botType ==='cibc'">{{ 'errorMessages.VALID_CIBC_EMAIL_ERROR' |
                                                translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" class="width-100-percent"
                                        *ngIf="isNewUser && editMode[index]">
                                        <mat-label>{{'rating.PASSWORD_ADMIN' |
                                            translate}}</mat-label>
                                        <input matInput #userPassword [(ngModel)]="row.password"
                                            [disabled]="!(isNewUser && index === 0) && !(editMode[index] && isNewUser)"
                                            name="username" required password #password="ngModel">
                                        <button mat-icon-button matSuffix
                                            [matTooltip]="'errorMessages.PASSWORD_INFO' | translate">
                                            <mat-icon>info</mat-icon>
                                        </button>
                                        <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                                            {{ 'errorMessages.PASSWORD_REQUIRED' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <div *ngIf="isNewUser && editMode[index]">
                                        <mat-form-field appearance="fill" class="width-100-percent">
                                            <mat-label>{{'rating.FIRST_NAME' |
                                                translate}}</mat-label>
                                            <input matInput #firstNameInput [(ngModel)]="firstName"
                                                [disabled]="!isNewUser" name="firstname" required firstname
                                                #firstname="ngModel">
                                            <mat-error
                                                *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">
                                                {{ 'errorMessages.FIRST_NAME_REQ_ERROR' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" class="width-100-percent">
                                            <mat-label>{{'rating.LAST_NAME' |
                                                translate}}</mat-label>
                                            <input matInput #lastNameInput [(ngModel)]="lastName"
                                                [disabled]="!isNewUser" name="lastname" required lastname
                                                #lastname="ngModel">
                                            <mat-error *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)">
                                                {{ 'errorMessages.LAST_NAME_REQ_ERROR' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div *ngIf="!editMode[index]">
                                    {{ row?.roles?.join(', ') }}
                                </div>
                                <!-- Display roles as a dropdown when in edit mode -->
                                <mat-form-field appearance="fill" class="width-100-percent" *ngIf="editMode[index]">
                                    <mat-label>{{ 'rating.ROLES' | translate }}</mat-label>

                                    <!-- Edit mode for existing records -->
                                    <mat-select multiple (selectionChange)="onRoleChange(index)"
                                        [(ngModel)]="roles[index]" *ngIf="!isNewUser">
                                        <mat-option *ngFor="let role of availableRoles" [disabled]="role === 'user'"
                                            [value]="role">
                                            {{ role }}
                                        </mat-option>
                                    </mat-select>

                                    <!-- Edit mode for new records -->
                                    <mat-select multiple (selectionChange)="onRoleChange(index)"
                                        [(ngModel)]="roles[index]" *ngIf="isNewUser" required name="roles"
                                        #role="ngModel">
                                        <mat-option *ngFor="let role of rolesArray"
                                            [value]="role">
                                            {{ role }}
                                        </mat-option>

                                    </mat-select>
                                    <mat-error *ngIf="role?.invalid && (role?.dirty || role?.touched)">
                                        {{ 'errorMessages.ROLES_REQ_ERROR' | translate }}
                                    </mat-error>


                                </mat-form-field>
                            </td>
                            <td>
                                <div class="action-even-space">
                                    <ng-container
                                        *ngIf="editMode[index]; then editModeEnabled else editModeDisabled"></ng-container>
                                    <ng-template #editModeEnabled>
                                        <button *ngIf="isEditEnabled" mat-button class="mui-button-clear"
                                            (click)="cancelEditMode(row, index)">{{'devConfig.CANCEL' |
                                            translate}}</button>
                                        <img src="assets/icons8-save-30.png" class="icon-button cursor" height="20"
                                            alt="Save Icon" (click)="handleSaveChanges(row, index)" [ngClass]="{
                                            'disabled': isSaveDisabled()
                                          }">
                                    </ng-template>
                                    <ng-template #editModeDisabled>
                                        <img src="assets/pen 1.svg" class="icon-button cursor" height="20"
                                            alt="Edit Icon" (click)="handleEditClick(row, index)"
                                            [ngClass]="isEditEnabled ? 'disabled' : '' || isNewUser ? 'disabled' : '' ">
                                    </ng-template>
                                    <span>
                                        <img src="assets/trash 1-black.svg"
                                            class="icon-button cursor {{ isNewUser ? 'disabled' : '' }}" height="20"
                                            alt="Delete" (click)="handleDelete(row, index)">
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="loader-center-align" *ngIf="!isLoader && data.length === 0">
            {{ 'contentMgmt.NO_RECORD_FOUND' | translate }}
        </div>

        <div class="pagination" *ngIf="!isLoader && data.length > 0 && searchQuery === ''">
            <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
                <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
            </button>
            <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
                <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
            </button>
            <span>{{ pageNumber }} / {{ totalTablePage }}</span>
            <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage">
                <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
            </button>
            <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage">
                <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
            </button>
        </div>
        <div *ngIf="isModalLoader" class="modal-backdrop">
            <app-loader [botLoderType]="botLoderType"></app-loader>
        </div>
    </div>