import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatbotService } from '../services/chat.service';
import { LocalStorageService } from '../services/localStorageService';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService, HttpLoaderFactory } from '../services/translation-service';
import { TranslateService } from '@ngx-translate/core';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  showPassword: boolean = false;
  passwordFieldType: string = 'password';
  usernameLabel = "";
  passwordLabel = "";
  translate: any;
  language: string = '';
  emailValid: boolean = true;
  botLoderType: any = 'boxLoader';
  isModalLoader: boolean = false;
  botType: any = '';
  constructor(
    public chatbotService: ChatbotService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private translateService: TranslationService,
    private translated: TranslateService,
    private dialog: MatDialog,
  ) {
    this.translate = translateService;
  }

  ngOnInit() {
    this.botType = this.localStorageService.get('bot_type');
    const storedLanguage = this.localStorageService.get('language');
    this.language = storedLanguage ? storedLanguage : 'en';
    this.translateService.setLanguage(this.language);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    this.passwordFieldType = this.showPassword ? 'text' : 'password';
  }

  // async handleSubmit(): Promise<void> {
  //   if (this.username !== "" && this.password !== "") {
  //     const sessionId = this.generateSessionId();
  //     const formData = {
  //       username: this.username,
  //       password: this.password,
  //       bot_type: 'cibc',
  //       sessionID: sessionId,
  //       force_login: false
  //     }
  //     await this.chatbotService.login(formData).then((loginData: any) => {
  //       if (loginData.token) {
  //         if (loginData.is_default_password) {

  //         } else {
  //           this.showSuccess();
  //           this.router.navigate(['/chatbot']);
  //           this.localStorageService.set('appTo', loginData?.token);
  //           this.localStorageService.set('username', loginData?.username);
  //           this.localStorageService.set('roles', loginData?.roles);
  //           this.localStorageService.set('profile_picture', loginData?.profile_picture);
  //           this.localStorageService.set('language', this.language);
  //           this.localStorageService.set('bot_type', loginData?.bot_type || 'cibc');
  //         }

  //       } else {
  //         this.router.navigate(['/login'])
  //       }
  //     }).catch((error: any) => {
  //       if (error.status === 401) {
  //         this.toastr.error('Login Expired')
  //       } else if (error.status === 404) {
  //         this.translated.get('errorMessages.LOGIN_FAILED_INVALID').subscribe((translatedText: string) => {
  //           this.toastr.error(translatedText);
  //         });
  //       }
  //     })

  //   } else if (this.username === "") {
  //     this.toastr.error("Please Enter User Name or Email ID!");
  //   } else if (this.password === "") {
  //     this.toastr.error('Please Enter Password!');
  //   }
  // }

  async handleSubmit(): Promise<void> {
    if (this.isInputValid()) {
      try {
        this.isModalLoader = true;
        const sessionId = this.generateSessionId();
        const formData = this.createFormData(sessionId);
        const loginData = await this.chatbotService.login(formData);

        this.handleLoginResponse(loginData);
      } catch (error) {
        this.handleLoginError(error);
      }
    } else {
      this.handleEmptyInputs();
    }
  }

  private isInputValid(): boolean {
    return this.username !== '' && this.password !== '';
  }

  private createFormData(sessionId: string) {
    return {
      username: this.username,
      password: this.password,
      bot_type: 'cibc',
      sessionID: sessionId,
      force_login: false
    };
  }

  private async handleLoginResponse(loginData: any): Promise<void> {
    if (!loginData.token) {
      this.router.navigate(['/login']);
      return;
    }
    this.saveLoginData(loginData);
    if (loginData.is_default_password) {
      // Handle default password case
      this.isModalLoader = false;
      const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
        width: 'auto',
        disableClose: true,
        data: {
          isPasswordReset: true,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.value) {
          this.isModalLoader = true;
          const payload = {
            username: this.localStorageService.get('username'),
            current_password: result?.value?.currentPassword,
            new_password: result?.value?.newPassword,
            bot_type: 'cibc'
          }
          this.chatbotService.changePassword(payload).subscribe((res: any) => {
            if (res.message) {
              this.isModalLoader = false;
              this.translated.get('successfullMessages.UPDATED_PASSWORD').subscribe((translatedText: string) => {
                this.toastr.success(translatedText);
              });
              this.username = '';
              this.password = '';
              this.router.navigate(['/login']);
            }
          }, (error: any) => {
            if (error?.status === 409) {
              this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
                this.toastr.error(translatedText);
              });
              this.router.navigate(['/login']);
              sessionStorage.clear();
            }
            if (error?.status === 401) {
              this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
                this.toastr.error(translatedText);
              });
              this.router.navigate(['/login']);
              sessionStorage.clear();
            }
            if (error?.status === 400) {
              this.isModalLoader = false;
              if (error?.error?.message === 'Current password cannot be same as previous.') {
                const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
                  width: 'auto',
                  disableClose: true,
                  data: {
                    passwordNotSamePrevious: true,
                  },
                });

                dialogRef.afterClosed().subscribe(result => {
                });
              } else if (error?.error?.message === 'New password does not meet the required criteria') {
                const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
                  width: 'auto',
                  disableClose: true,
                  data: {
                    passwordNotValid: true,
                  },
                });

                dialogRef.afterClosed().subscribe(result => {
                });
              } else if (error?.error?.message === 'Username mismatch') {
                this.translated.get('errorMessages.PASSWORD_USR_NAME_MISMATCH').subscribe((translatedText: string) => {
                  this.toastr.error(translatedText);
                });
              }
            }
          })
        }
      });
      return;
    }

    this.showSuccess();
    this.router.navigate(['/chatbot']);

  }

  private saveLoginData(loginData: any): void {
    const { token, username, roles, profile_picture, bot_type } = loginData;
    this.localStorageService.set('appTo', token);
    this.localStorageService.set('username', username);
    this.localStorageService.set('roles', roles);
    this.localStorageService.set('profile_picture', profile_picture);
    this.localStorageService.set('language', this.language);
    this.localStorageService.set('bot_type', bot_type || 'cibc');
  }

  private handleLoginError(error: any): void {
    this.isModalLoader = false;
    if (error.status === 401) {
      this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
      this.router.navigate(['/login']);
      this.localStorageService.clear();
    } else if (error.status === 404) {
      this.translated.get('errorMessages.LOGIN_FAILED_INVALID').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    } else {
      this.translated.get('errorMessages.LOGIN_SERVICE_FAILD').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    }
  }

  private handleEmptyInputs(): void {
    if (this.username === '') {
      this.translated.get('errorMessages.EMAIL_EMPTY_ERROR').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    } else if (this.password === '') {
      this.translated.get('errorMessages.PASSWORD_EMPTY_ERROR').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    }
  }

  showSuccess() {
    this.translated.get('login.LOGIN_SUCESS_TEXT').subscribe((translatedText: string) => {
      this.toastr.success(translatedText);
    });
  }
  clearFormData(): void {
    if (this.username !== "" || this.password !== "") {
      this.username = '';
      this.password = '';
    }
  }
  goToForgotPassword(): void {
    this.router.navigate(['/forgotpassword']);
  }
  generateSessionId(): string {
    return `session_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}`;
  }
  languageCheck() {
    if (this.language === 'en') {
      this.language = 'fr';
      this.translateService.setLanguage('fr');
      this.localStorageService.set('language', 'fr');
    } else if (this.language === 'fr') {
      this.language = 'en';
      this.translateService.setLanguage('en');
      this.localStorageService.set('language', 'en');
    }
  }
  emailDomainValidator(email: string): boolean {
    const emailPattern =/^(?=.{5,60}$)(?!.*\.\.)(?!.*@.*@)[a-zA-Z0-9._%-]+@(cibc\.com|cibc\.co|kumaran\.com|kumaran\.world)$/i;
    const isValid = emailPattern.test(email);
    return isValid;
  }
  validateEmail(emailControl: NgModel) {
    const email = emailControl.value;
    this.emailValid = this.emailDomainValidator(email);
    if (!this.emailValid) {
      emailControl.control.setErrors({ invalidEmail: true });
    } else {
      emailControl.control.setErrors(null);
    }
  }
}
