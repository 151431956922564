import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ChatTranscriptService } from "../services/chat-transcript.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateService } from "../services/shared-object.service";
import { Subscription } from 'rxjs';
import { TranslationService } from '../services/translation-service';
import { LocalStorageService } from '../services/localStorageService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateFormatService } from '../pipe/date-time-localize';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../services/chat.service';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-conversation-history',
  templateUrl: './conversation-history.component.html',
  styleUrls: ['./conversation-history.component.scss']
})
export class ConversationHistoryComponent implements OnInit, OnChanges {
  @Input() transcriptData: any[] = [];
  @ViewChild('TABLE')
  table!: ElementRef;
  loading: boolean = false;
  selectedConversation: any;
  currentPage: number = 1;
  totalTablePages = 0;
  pageSize: number = 20;
  email: string = '';
  startDate: any = '';
  endDate: any = '';
  searchData: any;
  private downloadSubscription: Subscription | undefined;
  transcriptDataSubscription: Subscription | undefined;
  transcriptDataSearchData: Subscription | undefined;
  translate: any;
  botLoderType = 'boxLoader'
  isMobileView: boolean = false;
  isModalLoader: boolean = false;
  isExpandCollapse: boolean = true;
  roles: any[];
  userType: string = '';
  //  email: string = '';
  // startDate: any = '';
  // endDate: any = '';
  startTime: any = null;
  endTime: any = null;
  hoursList: number[] = Array.from({ length: 24 }, (_, i) => i);
  endTimeInvalid: boolean = false;
  maxDate: Date;
  minDate: Date;
  today: Date = new Date();
  allUser: string = '';
  page_size = 20;
  page = 1;
  isFilter: boolean = true;
  isAccordianExpand = true;
  secondAccordionHeight = 'calc(100vh - 195px)';
  isChatLimitsReached: boolean = false;
  constructor(
    public transcriptService: ChatTranscriptService,
    private router: Router,
    private toastr: ToastrService,
    private stateSharedService: StateService,
    translateService: TranslationService,
    private sessionStorage: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    public dateFormatService: DateFormatService,
    private translated: TranslateService,
    private chatbotService: ChatbotService,
    public dialog: MatDialog
  ) {
    this.translate = translateService;
    this.roles = sessionStorage.get('roles');
    const today = new Date();
    this.maxDate = today;
    this.minDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
    this.startDate = new Date();
    this.endDate = new Date();
    this.email = this.sessionStorage.get('username');
    this.isAccordianExpand = this.checkInitialAccordionState();
  }
  checkInitialAccordionState(): boolean {
    return true;
  }
  onAccordionToggle(isExpanded: boolean): void {
    if (isExpanded) {
      this.secondAccordionHeight = 'calc(100vh - 410px)';
    } else {
      this.secondAccordionHeight = 'calc(100vh - 195px)';
    }
  }
  ngOnInit() {
    this.updateLoadingState();
    this.transcriptDataSubscription = this.transcriptService.transcriptData$.subscribe(data => {
      const totalPagesObject = data.find(item => item.hasOwnProperty('totalPages'));
      if (totalPagesObject !== undefined) {
        this.totalTablePages = totalPagesObject?.totalPages;
      }
      this.transcriptData = [...data];
      this.updateLoadingState();
    });

    this.transcriptDataSearchData = this.stateSharedService.searchData$.subscribe(data => {
      this.searchData = data;
      this.currentPage = this.searchData.page;
    });
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileView = result.matches;
        if (this.isMobileView) {
          this.isExpandCollapse = true; // Example: collapse the sidenav on mobile
        }
      });
    this.stateSharedService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
    if (this.roles?.includes('chatanalyst')) {
      this.userType = 'all';
    } else {
      this.userType = 'specified';
    }
  }


  onSearch(searchCriteria: { email: string, startDate: string, endDate: string, page: number, pageSize: number, startTime: number, endTime: number }) {
    this.transcriptData = [];
    this.email = searchCriteria.email || this.email;
    this.startDate = searchCriteria.startDate;
    this.endDate = searchCriteria.endDate;
    const startDate = new Date(this.startDate);
    startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());

    const endDate = new Date(this.endDate);
    endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const searchData = {
      user_string: this.userType === 'all' ? '' : this.email.toLowerCase(),
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
      bot_type: this.sessionStorage.get('bot_type'),
      language_type: this.sessionStorage.get('language'),
      start_hour: searchCriteria.startTime,
      timezone_offset: timeZone,
      end_hour: searchCriteria.endTime,
    }
    this.transcriptService.getChatTranscriptData(searchData, this.pageSize, this.currentPage).subscribe((transcriptResponse) => {
      const totalPagesObject = transcriptResponse.find((item: any) => item.hasOwnProperty('totalPages'));
      if (totalPagesObject !== undefined) {
        this.totalTablePages = totalPagesObject?.totalPages;
      }
      const conLimReached = transcriptResponse.find((item: any) => item.hasOwnProperty('conversation_limit_reached'));
      if (conLimReached !== undefined) {
        this.isChatLimitsReached = conLimReached?.conversation_limit_reached;
      }
      this.transcriptData = this.removeLastTwoObjects(transcriptResponse);
      this.updateLoadingState();
    }, (error) => {
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    })
  }

  removeLastTwoObjects(array: any[]): any[] {
    const arrayLength = array.length;

    if (arrayLength >= 2) {
      const lastElement = array[arrayLength - 1];
      const secondLastElement = array[arrayLength - 2];

      // Check if the last two objects contain the specific keys
      if (
        lastElement?.conversation_limit_reached !== undefined &&
        secondLastElement?.totalPages !== undefined
      ) {
        // Remove the last two elements
        array.splice(-2, 2);
      } else {
        console.log('Last two elements do not match the criteria.');
      }
    } else {
      console.log('Array does not have enough elements.');
    }

    return array;
  }


  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.onSearch({ email: this.email, startDate: this.startDate, endDate: this.endDate, page: this.currentPage, pageSize: this.pageSize, startTime: this.startTime, endTime: this.endTime });
  }

  exportToExcel(): void {
    this.downloadTranscript({ email: this.searchData.email, startDate: this.searchData.startDate, endDate: this.searchData.endDate, page: this.currentPage, pageSize: this.pageSize, startTime: this.searchData.startTime, endTime: this.searchData.endTime });
  }

  async downloadTranscript(searchCriteria: { email: string, startDate: any, endDate: any, page: number, pageSize: number, startTime: number, endTime: number }) {

    this.isModalLoader = true;
    const { email, startDate, endDate, page, pageSize, startTime, endTime } = searchCriteria;

    // const formattedStartDate = this.dateFormatService.convertUtcToLocal24HRS(startDate);

    // const formattedEndDate = this.dateFormatService.convertUtcToLocal24HRS(endDate);
    const formattedStartDate = new Date(startDate);
    formattedStartDate.setMinutes(formattedStartDate.getMinutes() - formattedStartDate.getTimezoneOffset());

    const formattedEndDate = new Date(endDate);
    formattedEndDate.setMinutes(formattedEndDate.getMinutes() - formattedEndDate.getTimezoneOffset());

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let bodyFormData = {
      user_string: email,
      start_date: formattedStartDate.toISOString().split('T')[0],
      end_date: formattedEndDate.toISOString().split('T')[0],
      bot_type: this.sessionStorage.get('bot_type'),
      timezone_offset: timeZone,
      language_type: this.sessionStorage.get('language'),
      start_hour: startTime,
      end_hour: endTime
    }
    this.downloadSubscription = (await this.transcriptService.downloadTranscript(bodyFormData, pageSize, page))
      .subscribe(
        (downloadResponse: Blob) => {
          this.isModalLoader = false
          const contentType = downloadResponse.type;
          if (contentType === 'text/csv') {
            const blob = new Blob([downloadResponse], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'chat_transcript.csv'; // Set the desired filename
            //   a.addEventListener('click', () => {
            //     // Display toaster message after the click event
            //     this.toastr.success('File has been downloaded successfully');
            // });
            a.click();
          }
        },
        (error: any) => {
          this.isModalLoader = false;
          if (error.status === 409) {
            this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 500) {
            this.translated.get('errorMessages.FAILED_DOWNLOAD_ERROR_TRANSCRIPT').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
          }
        }
      );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.transcriptDataSubscription) {
      this.transcriptDataSubscription.unsubscribe();
    }
    if (this.transcriptDataSearchData) {
      this.transcriptDataSearchData.unsubscribe();
    }
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.updateLoadingState();
  }

  private updateLoadingState() {
    if (this.transcriptData.length === 0) {
      this.loading = false;
    }
  }
  openDrawer(conversation: any): void {
    this.selectedConversation = conversation;
    // Open the drawer
    this.selectedConversation.drawerOpen = true;
  }

  handleDrawerClosed(): void {
    // Update any necessary state in the parent component
    // For example, clear selectedConversation or any other relevant action
    this.selectedConversation = null;
  }
  isChatConversationHistory(): boolean {
    return this.router.url.includes('/conversationhistory');
  }
  onUserTypeChange(event: any) {
    const windowWidth = window.innerWidth;
  }

  shouldDisableEmail(roles: string[]): boolean {
    return (!roles?.includes('chatanalyst'));
  }
  validateDates() {
    if (!this.startDate || !this.endDate) {
      return;
    }

    // Reset start and end times if dates are not the same
    if (!this.isSameDate(this.startDate, this.endDate)) {
      this.startTime = null;
      this.endTime = null;
    }

  }

  validateTimes() {
    if (this.endTime && this.startTime && this.endTime < this.startTime) {
      this.endTimeInvalid = true;
    } else {
      this.endTimeInvalid = false;
    }
  }
  isSameDate(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) {
      return false;
    }
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }
  isValidEmail(input: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Split input by commas and trim whitespace
    const emails = input.split(',').map(email => email.trim());

    // Check if all emails are valid
    return emails.every(email => emailRegex.test(email));
  }

  // Check form validity
  isFormValid(): boolean {
    // Check if mandatory fields are filled
    if (!this.startDate || !this.endDate) {
      return false;
    }

    // Validate email if "specified" userType is selected
    if (this.userType === 'specified' && (!this.email || !this.isValidEmail(this.email))) {
      return false;
    }

    // If start and end dates are the same, validate times
    if (this.isSameDate(this.startDate, this.endDate)) {
      if (!this.startTime || !this.endTime || this.startTime >= this.endTime) {
        return false;
      }
    }

    // All validations passed
    return true;
  }

  onClear() {
    if (this.email !== "" || this.startDate !== "" || this.endDate !== "") {
      this.email = '';
      this.startDate = null;
      this.endDate = null;
      this.startTime = null;
      this.endTime = null;
      this.transcriptService.updateTranscriptData([]);
    } else {
      this.translated.get('errorMessages.CLEAR_ERROR_CONTENT_MGMT').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    }
  }

  onSearchChat() {
    if (this.startDate !== "" && this.endDate !== "") {
      this.isFilter = false;
      const startDate = new Date(this.startDate);
      startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
      const endDate = new Date(this.endDate);
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
      this.allUser = this.userType === 'all' ? "" : this.email.toLowerCase();
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const searchData = {
        user_string: this.userType === 'all' ? "" : this.email.toLowerCase(),
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        bot_type: this.sessionStorage.get('bot_type'),
        language_type: this.sessionStorage.get('language'),
        start_hour: null,
        end_hour: null,
        timezone_offset: timeZone,
      }
      if (this.isSameDate(this.startDate, this.endDate)) {
        searchData.start_hour = this.startTime;
        searchData.end_hour = this.endTime;
      }
      // this.actionClickedFromSideNav.emit('closeSideNav');
      this.transcriptService.updateTranscriptData([]);
      this.transcriptService.getChatTranscriptData(searchData, this.page_size, this.page).subscribe((transcriptResponse) => {
        if (Array.isArray(transcriptResponse) && transcriptResponse.length > 0) {
          const totalPagesObject = transcriptResponse.find((item: any) => item.hasOwnProperty('totalPages'));
          if (totalPagesObject !== undefined) {
            this.totalTablePages = totalPagesObject?.totalPages;
          }
          const conLimReached = transcriptResponse.find((item: any) => item.hasOwnProperty('conversation_limit_reached'));
          if (conLimReached !== undefined) {
            this.isChatLimitsReached = conLimReached?.conversation_limit_reached;
          }
          if (this.isChatLimitsReached) {
            const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
              width: 'auto',
              disableClose: true,
              data: {
                isChatLimitsReached: true,
              }
            });

            dialogRef.afterClosed().subscribe(result => {
            });
          }
          const trimmedTranscriptData = this.removeLastTwoObjects(transcriptResponse);
          this.transcriptService.updateTranscriptData(trimmedTranscriptData);
        }
      }, (error) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } else if (error.status === 500) {
          this.translated.get('errorMessages.FAILD_LOAD_DATA').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
        } else if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } else if (error.status === 400) {
          if (error.error.startsWith('Search Limit Exceeds the Threshold :')) {
            this.translated.get('errorMessages.CHAT_TRANSCTIPT_MSG_INFOR').subscribe((translatedText: string) => {
              this.toastr.info(translatedText);
            });
          }
          this.transcriptService.updateTranscriptData([]);
        }
      })
      this.stateSharedService.emitSearchData({ email: this.allUser, startDate: this.startDate, endDate: this.endDate, page: this.page, pageSize: this.page_size, startTime: this.startTime, endTime: this.endTime });
    } else if (this.email === "") {
      this.translated.get('errorMessages.EMAIL_EMPTY_ERROR').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    } else if (this.startDate === "") {
      this.translated.get('errorMessages.START_DATE_EMPTY').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    } else if (this.endDate === "") {
      this.translated.get('errorMessages.END_DATE_EMPTY').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    }
  }
  clearSearchData() {
    this.isFilter = true;
  }
}
